export function fromDraftLegalTerms(legalTerms) {
  if (!legalTerms) {
    return {
      id: '',
      contentType: 'richText',
      name: '',
      richTextContent: []
    };
  }
  return legalTerms.type === 'pdf' ? {
    id: legalTerms.libraryLegalTermsId,
    contentType: 'pdf',
    name: legalTerms.displayName || '',
    pdfUniqueName: legalTerms.pdfUniqueName || ''
  } : {
    id: legalTerms.type === 'libraryLegalTermsId' ? legalTerms.libraryLegalTermsId : '',
    contentType: 'richText',
    name: '',
    richTextContent: legalTerms.type === 'richText' ? legalTerms.richTextContent : []
  };
}
function duplciateLegalTerms(legalTerms) {
  if (legalTerms.contentType === 'pdf') {
    return {
      type: 'pdf',
      displayName: legalTerms.name,
      pdfUniqueName: legalTerms.pdfUniqueName,
      libraryLegalTermsId: legalTerms.id
    };
  } else {
    return {
      type: 'richText',
      displayName: legalTerms.name,
      richTextContent: legalTerms.richTextContent
    };
  }
}
export function toDraftLegalTerms(legalTerms, legalTermsNotFoundInLibrary) {
  return legalTermsNotFoundInLibrary ? duplciateLegalTerms(legalTerms) : {
    libraryLegalTermsId: legalTerms.id,
    type: 'libraryLegalTermsId'
  };
}