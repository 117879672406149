import { useOnMount } from "./useOnMount";
import { useIoContext } from "../contexts";
import * as Sentry from '@sentry/react';
import { routes } from "../constants";

/** Syncs the authToken between multiple active browser-tabs. Makes sure all tabs are set to the same user and business. */
export function useSyncUserDataBetweenTabs() {
  const {
    changeLocation,
    cookies
  } = useIoContext();
  useOnMount(() => {
    async function onStorageEvent(event) {
      if (cookies.didAuthTokenChange(event)) {
        Sentry.addBreadcrumb({
          category: 'syncAuthToken',
          data: {
            oldValue: event.oldValue,
            newValue: event.newValue
          }
        });
        changeLocation(routes.home);
      }
    }
    window.addEventListener('storage', onStorageEvent);
    return () => {
      window.removeEventListener('storage', onStorageEvent);
    };
  });
}