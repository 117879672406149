import { PaymentMethodTypes, PlaidBankAccountStatusTypes, BankAccountStatusTypes, VerifyWithEnum } from "./model";
export function isVerified(paymentMethod) {
  if (isCreditCard(paymentMethod)) return true;
  const {
    bankAccount
  } = paymentMethod;
  return bankAccount.status === BankAccountStatusTypes.Validated || bankAccount.status === BankAccountStatusTypes.Verified || bankAccount.verificationStatus === PlaidBankAccountStatusTypes.AutomaticallyVerified || bankAccount.verificationStatus === PlaidBankAccountStatusTypes.ManuallyVerified;
}
export function failedVerification(paymentMethod) {
  if (!isBankAccount(paymentMethod)) return false;
  const {
    bankAccount
  } = paymentMethod;
  return bankAccount.verificationStatus === PlaidBankAccountStatusTypes.VerificationExpired || bankAccount.verificationStatus === PlaidBankAccountStatusTypes.VerificationFailed || bankAccount.status === BankAccountStatusTypes.PlaidVerificationFailed || bankAccount.status === BankAccountStatusTypes.TwoAttemptsFailed || bankAccount.status === BankAccountStatusTypes.VerificationFailed;
}
export function isPendingManualVerification(paymentMethod) {
  return isBankAccount(paymentMethod) && paymentMethod.bankAccount.verificationStatus === PlaidBankAccountStatusTypes.PendingManualVerification;
}
export function isFailedDueToDuplicate(paymentMethod) {
  return isBankAccount(paymentMethod) && paymentMethod.bankAccount.status === BankAccountStatusTypes.FailedDueToDuplicate;
}
export function requiresVerification(paymentMethod) {
  if (!isBankAccount(paymentMethod)) return false;
  const {
    bankAccount
  } = paymentMethod;
  return isBankAccount(paymentMethod) && (bankAccount.status === BankAccountStatusTypes.New || bankAccount.verificationStatus === PlaidBankAccountStatusTypes.PendingAutomaticVerification || bankAccount.verificationStatus === PlaidBankAccountStatusTypes.PendingManualVerification);
}
export function isBankAccount(paymentMethod) {
  return paymentMethod.type === PaymentMethodTypes.BankAccount;
}
export function last4(paymentMethod) {
  return paymentMethod.last4;
}
export function isCreditCard(paymentMethod) {
  return paymentMethod.type === PaymentMethodTypes.CreditCard;
}
export function isVerifyWithStripe(paymentMethod) {
  return isBankAccount(paymentMethod) && paymentMethod.bankAccount.verifyWith === VerifyWithEnum.Stripe;
}
export function institutionId(paymentMethod) {
  return isBankAccount(paymentMethod) ? paymentMethod.bankAccount.institutionId : null;
}
export function verificationStatus(paymentMethod) {
  return isBankAccount(paymentMethod) ? paymentMethod.bankAccount.verificationStatus : null;
}
export function verificationIncomplete(paymentMethod) {
  if (!isBankAccount(paymentMethod)) return false;
  const {
    bankAccount
  } = paymentMethod;
  return bankAccount.status === BankAccountStatusTypes.New || bankAccount.status === BankAccountStatusTypes.TwoAttemptsFailed || bankAccount.status === BankAccountStatusTypes.VerificationFailed || bankAccount.verificationStatus === PlaidBankAccountStatusTypes.PendingAutomaticVerification || bankAccount.verificationStatus === PlaidBankAccountStatusTypes.PendingManualVerification || bankAccount.verificationStatus === PlaidBankAccountStatusTypes.VerificationExpired || bankAccount.verificationStatus === PlaidBankAccountStatusTypes.VerificationFailed;
}
export function isErroredBankAccount(bankAccount) {
  return bankAccount.status === BankAccountStatusTypes.Errored;
}