import { useGtm } from "./gtm";
import { appcues } from "./appcues";
import { heap } from "./heap";
import { hubspot } from "./hubspot";
import { useMemo } from 'react';
export default function useEventTargets() {
  const gtm = useGtm();
  return useMemo(() => ({
    appcues,
    heap,
    hubspot,
    gtm
  }), []);
}
export function getMockEventTargets() {
  return {
    appcues: {
      identify: () => {},
      sendEventDeprecated: () => {},
      report: () => {},
      sendPageView: () => {}
    },
    heap: {
      resetIdentity: () => {},
      identify: () => {},
      sendEventDeprecated: () => {},
      report: () => {}
    },
    hubspot: {
      resetIdentity: () => {},
      identify: () => {},
      sendEventDeprecated: () => {},
      report: () => {},
      sendPageView: () => {}
    },
    gtm: {
      sendEventDeprecated: () => {},
      report: () => {},
      sendPageView: () => {}
    }
  };
}