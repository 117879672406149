import lastDayOfMonth from 'date-fns/lastDayOfMonth';
export const chartDataForLoading = [{
  date: '03/03',
  paid: 4500,
  in_transit: 0,
  clear: 0,
  future: 0
}, {
  date: '03/04',
  paid: 12500,
  in_transit: 0,
  clear: 0,
  future: 0
}, {
  date: '03/05',
  paid: 7000,
  in_transit: 0,
  clear: 0,
  future: 0
}, {
  date: '03/07',
  paid: 0,
  in_transit: 6000,
  clear: 0,
  future: 0
}, {
  date: '03/09',
  paid: 0,
  in_transit: 4900,
  clear: 0,
  future: 0
}, {
  date: '03/10',
  paid: 0,
  in_transit: 2500,
  clear: 1500,
  future: 0
}, {
  date: '03/11',
  paid: 0,
  in_transit: 4000,
  clear: 4000,
  future: 0
}, {
  date: '03/16',
  paid: 0,
  in_transit: 0,
  clear: 1500,
  future: 0
}, {
  date: '03/21',
  paid: 0,
  in_transit: 0,
  clear: 3000,
  future: 0
}, {
  date: '03/22',
  paid: 0,
  in_transit: 0,
  clear: 0,
  future: 4000
}, {
  date: '03/25',
  paid: 0,
  in_transit: 0,
  clear: 0,
  future: 3000
}, {
  date: '03/27',
  paid: 0,
  in_transit: 0,
  clear: 0,
  future: 5500
}];
export const getTotal = item => item ? item.total / 100 : 0;

/** Fills all missing days for this month with empty data. */
export const fillMissingDays = calendar => {
  const now = new Date();
  const month = now.getMonth() + 1;
  const year = now.getFullYear();
  const lastDayInMonth = lastDayOfMonth(now).getDate();
  const filled = [];
  let dayToFill = 1;

  // Fill all days between the calendar items.
  calendar.forEach(item => {
    while (dayToFill < item.date.day) {
      filled.push(getEmptyCalendarItem(dayToFill, month, year));
      dayToFill++;
    }
    filled.push(item);
    dayToFill++;
  });

  // Fill the rest of the days till end of month.
  while (dayToFill <= lastDayInMonth) {
    filled.push(getEmptyCalendarItem(dayToFill, month, year));
    dayToFill++;
  }
  return filled;
};
const getEmptyCalendarItem = (day, month, year) => ({
  date: {
    day,
    month,
    year
  },
  statuses: {}
});