export function composeValidators(validators) {
  const rules = {
    validate: {}
  };
  validators.forEach(validator => {
    if (validator.validate && typeof validator.validate === 'object') {
      Object.assign(rules.validate, validator.validate);
    }
  });
  return rules;
}