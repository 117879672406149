// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dakRx{display:flex;flex-direction:column;gap:8px}.x9atd{display:flex;align-items:center;gap:16px}.lp4tX{flex-shrink:0}.BJiz8{display:flex;flex-direction:column;align-items:flex-start;gap:12px}.ARSxU{display:none}.h4VPU{display:flex;flex-direction:column;max-width:100%;margin-inline-start:12px}.YjtaC{display:flex;align-items:center;justify-content:space-between;gap:8px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fileUpload": `dakRx`,
	"fileUploadButtonWrapper": `x9atd`,
	"fileUploadButton": `lp4tX`,
	"fileSection": `BJiz8`,
	"fileInput": `ARSxU`,
	"files": `h4VPU`,
	"fileRow": `YjtaC`
};
export default ___CSS_LOADER_EXPORT___;
