import { ApprovalMethodNoticePeriodType } from "./model";
export function makeAutoApprovalMethodNoticePeriod(noticePeriodDays) {
  return {
    type: ApprovalMethodNoticePeriodType.Automatic,
    auto: {
      noticePeriodDays
    }
  };
}
export function makeManualApprovalMethodNoticePeriod() {
  return {
    type: ApprovalMethodNoticePeriodType.Manual
  };
}