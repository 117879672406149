import { ColumnPaymentsStatus, UserRole } from "./model";
export const business = userInfo => userInfo.worksAt;
export function disabledPayments(userInfo) {
  const worksAt = business(userInfo);
  return worksAt ? worksAt.disablePayments : false;
}

/** Returns the role of the user in his current business. */
export function role(userInfo) {
  var _business;
  return (_business = business(userInfo)) === null || _business === void 0 ? void 0 : _business.role;
}
export function isVendor(userInfo) {
  var _business2;
  return !!((_business2 = business(userInfo)) !== null && _business2 !== void 0 && (_business2 = _business2.features) !== null && _business2 !== void 0 && _business2.accountsReceivable);
}
export function isClient(userInfo) {
  var _business3;
  return !!((_business3 = business(userInfo)) !== null && _business3 !== void 0 && (_business3 = _business3.features) !== null && _business3 !== void 0 && _business3.accountsPayable);
}
export function isOwner(userInfo) {
  return role(userInfo) === UserRole.Owner;
}
export function isAdmin(userInfo) {
  return role(userInfo) === UserRole.Admin;
}
export function paymentsDisabled(userInfo) {
  const worksAt = business(userInfo);
  return !!(worksAt !== null && worksAt !== void 0 && worksAt.disablePayments);
}
export function columnEntityCreated(userInfo) {
  const worksAt = business(userInfo);
  return !!worksAt && worksAt.column.paymentsStatus === ColumnPaymentsStatus.Enabled;
}
export function hasColumnAccountId(userInfo) {
  const worksAt = business(userInfo);
  return !!worksAt && !!worksAt.column.accountId;
}
export function hasColumnWalletAccountId(userInfo) {
  const worksAt = business(userInfo);
  return !!worksAt && !!worksAt.column.walletAccountId;
}
export function hasColumnPayoutAccountId(userInfo) {
  const worksAt = business(userInfo);
  return !!worksAt && !!worksAt.column.payoutAccountId;
}