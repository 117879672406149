import { BillMode } from "../ManualBillModal/ManualBillModal.utils";
import { utils } from "../../utils";
import { DiscountModel, DiscountType } from "../../models";
import uniqBy from 'lodash/uniqBy';
import { sortAlphabetically } from "../../infra/utils";
import { isSameDay } from 'date-fns';
export const initialDiscount = {
  type: DiscountType.Percent,
  amount: ''
};
export const getOptionSelected = (option, value) => option.value === value.value;
export const getOptionLabel = option => {
  if (!option) return '';
  return typeof option === 'string' ? option : option.label;
};
export function getSuccessToastText(mode) {
  switch (mode) {
    case BillMode.Now:
      return 'The charge was billed successfully';
    case BillMode.Upcoming:
      return 'The charge was added to the upcoming bill';
    case BillMode.FutureDate:
      return 'The charge was added to a future date';
  }
}
export const validateGreaterThan0 = value => value && +value > 0;
export function getTableInfo(discount) {
  const tableInfo = [{
    headerItem: {
      value: 'Service name'
    },
    columnDef: {
      width: 250
    }
  }, {
    headerItem: {
      value: 'Quantity'
    },
    columnDef: {
      width: 110
    }
  }, {
    headerItem: {
      value: 'Rate'
    },
    columnDef: {
      width: 150
    }
  }, {
    headerItem: {
      value: discount.type === DiscountType.Percent ? 'Discount' : 'Unit discount'
    },
    columnDef: {
      width: 140
    }
  }, {
    headerItem: {
      value: 'Total'
    },
    columnDef: {
      width: [140, 230]
    }
  }];
  return {
    columnDefs: tableInfo.map(i => i.columnDef),
    headerItems: tableInfo.map(i => i.headerItem)
  };
}
export function getTotal(quantity, rate, selectedServiceOption, discount) {
  if (!selectedServiceOption || !rate || !quantity) return;
  return utils.calculateFinalPrice(+rate, DiscountModel.make(discount.type, +discount.amount), +quantity);
}
export function getClientOptions(inRelationshipContext, agreements) {
  if (inRelationshipContext) return [];
  const unfiltered = (agreements || []).map(a => ({
    value: a.customer.id,
    label: a.customer.name || ''
  }));
  const unique = uniqBy(unfiltered, 'label');
  return sortAlphabetically(unique, 'label', {
    ascending: true,
    ignoreCase: true
  });
}
export function getServiceTemplateOptions(serviceTemplates, isLoading) {
  if (isLoading) return [];
  const options = serviceTemplates.map(st => ({
    value: st.id,
    label: st.name
  }));
  return sortAlphabetically(options, 'label', {
    ascending: true,
    ignoreCase: true
  });
}
export function getClientApprovalCalloutText(issueDate, invoiceDateInfo) {
  return invoiceDateInfo !== null && invoiceDateInfo !== void 0 && invoiceDateInfo.futureInvoicesDates.some(d => isSameDay(d, issueDate)) ? 'Adding an out-of-agreement charge to an existing invoice will require your client to manually approve the payment for the entire invoice' : 'Billing your client with out-of-agreement charges will require your client to manually approve the payment';
}
export function discountToDTO(discount) {
  if (discount.amount) {
    const amountAsNumber = +discount.amount;
    return amountAsNumber ? {
      type: discount.type,
      temp_amount: utils.dollarsToCents(amountAsNumber)
    } : undefined;
  } else {
    return undefined;
  }
}