// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.l92UI{position:relative}.l92UI:not(.S93Bm){display:grid;grid-template-columns:272px 272px 272px;gap:20px 16px}.l92UI.S93Bm{display:flex;flex-direction:column;gap:16px}.ALR9t{display:flex;flex-direction:column;gap:16px}.aAH9b{position:relative;background:#fcfcfd;border-radius:4px;padding:24px;grid-column-start:span 2;box-shadow:0px 4px 12px rgba(40,40,99,.06);background-color:var(--bg-light, #ffffff)}.iyEcz{position:absolute;top:-8px;left:0;color:var(--bg-light, #ffffff);transform:rotate(180deg)}.b4Cg2{position:relative}.b4Cg2.mJycY{margin-top:40px}.IjzVN{margin-bottom:4px}.IjzVN.CiIUO{margin-bottom:16px}.b1a_N{position:absolute;width:100%;margin-top:4px;color:var(--text-standard, #282863)}.Nkr1W{font-size:var(--font-size-p1, 16px);line-height:var(--line-height-p1, 24px);letter-spacing:.015em;font-family:"Lufga-Regular";font-weight:400;box-sizing:border-box;width:100%;background:#f4f4f7;border-bottom:1px solid #696992;padding:4px 8px;outline:0;color:#696992}.Nkr1W.MubIg{border-bottom-color:#eb7272}.Nkr1W.XkDwB{border-bottom-color:#56d0b5}.YPYWn{margin:24px 0}.wDBDj{margin-bottom:16px}.EVbxI{margin-top:20px}.EVbxI:not(.S93Bm){max-width:560px}.EVbxI.S93Bm{max-width:100%}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blue45": `#fcfcff`,
	"blue48": `#f5f7fd`,
	"blue50": `#f3f5ff`,
	"blue300": `#dae0ff`,
	"blue500": `#c0caff`,
	"blue600": `#a1aef5`,
	"blue700": `#8296ff`,
	"blue800": `#6176e7`,
	"dark50": `#fcfcfd`,
	"dark70": `#f4f4f7`,
	"dark100": `#eaeaef`,
	"dark120": `#e4e4ec`,
	"dark200": `#d4d4e0`,
	"dark300": `#bfbfd0`,
	"dark400": `#a9a9c1`,
	"dark500": `#9393b1`,
	"dark600": `#7e7ea1`,
	"dark700": `#696992`,
	"dark800": `#535382`,
	"dark900": `#282863`,
	"green45": `#eff6fa`,
	"green50": `#f3fcfa`,
	"green300": `#b6ebdf`,
	"green500": `#86ddca`,
	"green700": `#56d0b5`,
	"green800": `#36b89b`,
	"green900": `#089475`,
	"orange50": `#fdf2dc`,
	"orange300": `#fadea8`,
	"orange500": `#f9d797`,
	"orange700": `#f7ca75`,
	"orange800": `#f5bd52`,
	"orange900": `#dd9306`,
	"purple50": `#e1d9fe`,
	"purple300": `#c3b3fc`,
	"purple500": `#a58efb`,
	"purple700": `#8768f9`,
	"purple800": `#6942f8`,
	"purple900": `#4520cc`,
	"red200": `#fdf0f0`,
	"red300": `#fee3e3`,
	"red500": `#f9d5d5`,
	"red700": `#f3aaaa`,
	"red900": `#eb7272`,
	"red950": `#e86161`,
	"red970": `#dd4c4c`,
	"white": `#fff`,
	"defaultTextStandard": `#282863`,
	"defaultTextSecondary": `#696992`,
	"defaultButtonPrimary": `#6942f8`,
	"buttonPrimary": `var(--button-primary, #6942f8)`,
	"buttonPrimaryDark": `var(--button-primary-dark, #4520cc)`,
	"buttonDisabled": `var(--button-disabled, #bfbfd0)`,
	"buttonDisabledDark": `var(--button-disabled-dark, #a9a9c1)`,
	"defaultButtonDisabled": `#bfbfd0`,
	"defaultStepperCompleted": `#089475`,
	"defaultStepperIncomplete": `#d4d4e0`,
	"defaultButtonSecondary": `#fcfcfd`,
	"defaultBgStandard": `#f3f5ff`,
	"defaultBgLight": `#fff`,
	"sh10": `0px 4px 12px rgba(40,40,99,.06)`,
	"sh20": `0px 4px 16px rgba(40,40,99,.08)`,
	"sh40": `0px 4px 24px rgba(40,40,99,.12)`,
	"sh60": `0px 4px 14px rgba(40,40,99,.24)`,
	"fontFamilyLight": `"Lufga-Regular"`,
	"fontFamilyNormal": `"Lufga-Medium"`,
	"fontFamilyBold": `"Lufga-SemiBold"`,
	"fontSizeH1": `var(--font-size-h1, 48px)`,
	"fontSizeH2": `var(--font-size-h2, 40px)`,
	"fontSizeH3": `var(--font-size-h3, 33px)`,
	"fontSizeH4": `var(--font-size-h4, 28px)`,
	"fontSizeH5": `var(--font-size-h5, 22px)`,
	"fontSizeH6": `var(--font-size-h6, 20px)`,
	"fontSizeP1": `var(--font-size-p1, 16px)`,
	"fontSizeP2": `var(--font-size-p2, 14px)`,
	"fontSizeP3": `var(--font-size-p3, 12px)`,
	"lineHeightH1": `var(--line-height-h1, 64px)`,
	"lineHeightH2": `var(--line-height-h2, 56px)`,
	"lineHeightH3": `var(--line-height-h3, 40px)`,
	"lineHeightH4": `var(--line-height-h4, 40px)`,
	"lineHeightH5": `var(--line-height-h5, 32px)`,
	"lineHeightH6": `var(--line-height-h6, 35px)`,
	"lineHeightP1": `var(--line-height-p1, 24px)`,
	"lineHeightP2": `var(--line-height-p2, 18px)`,
	"lineHeightP3": `var(--line-height-p3, 16px)`,
	"options": `l92UI`,
	"isMobile": `S93Bm`,
	"existingPaymentMethods": `ALR9t`,
	"box": `aAH9b`,
	"arrow": `iyEcz`,
	"field": `b4Cg2`,
	"marginTop": `mJycY`,
	"label": `IjzVN`,
	"mediumSize": `CiIUO`,
	"error": `b1a_N`,
	"input": `Nkr1W`,
	"invalid": `MubIg`,
	"complete": `XkDwB`,
	"addPaymentMethod": `YPYWn`,
	"noAddPaymentTextButton": `wDBDj`,
	"inviteMemberCallout": `EVbxI`
};
export default ___CSS_LOADER_EXPORT___;
