// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gjFSb{display:flex;justify-content:space-between;min-height:290px}.gjFSb>:first-child{border-right:1px solid #eaeaef;width:364px;min-width:364px}.gjFSb>:last-child{flex:1}.oOwnS{width:100%;text-align:start;display:flex;justify-content:space-between;align-items:center;padding:12px 20px 0 24px}.HJveS{display:flex;align-items:center;justify-content:flex-end;color:#696992}.WOcb4{display:flex;justify-content:space-between;margin-bottom:4px}.I58gk{display:flex;align-items:center;gap:27px}.JURVQ{width:316px}.vLIUJ{padding:12px 24px;width:100%;text-align:inherit;display:flex;align-items:center;justify-content:space-between}.vLIUJ:hover,.vLIUJ.vhIUG{background:#f4f4f7}.YPe7E{margin-inline-start:6px}.o7yRx{padding:16px;background:#f4f4f7;border-radius:8px;display:inline-block;position:relative}.UeYai{padding:0 32px 26px 48px;display:flex;justify-content:space-between;block-size:100%}.UeYai>:first-child{display:flex;flex-direction:column;justify-content:space-between;gap:8px}.UeYai>:first-child>*{flex:1 0 50%}.UeYai>:last-child{display:flex;align-items:center}.Jyj0K{color:#089475}.HjbGr{margin-block-end:20px}.vV1LH{max-width:840px;overflow:hidden}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blue45": `#fcfcff`,
	"blue48": `#f5f7fd`,
	"blue50": `#f3f5ff`,
	"blue300": `#dae0ff`,
	"blue500": `#c0caff`,
	"blue600": `#a1aef5`,
	"blue700": `#8296ff`,
	"blue800": `#6176e7`,
	"dark50": `#fcfcfd`,
	"dark70": `#f4f4f7`,
	"dark100": `#eaeaef`,
	"dark120": `#e4e4ec`,
	"dark200": `#d4d4e0`,
	"dark300": `#bfbfd0`,
	"dark400": `#a9a9c1`,
	"dark500": `#9393b1`,
	"dark600": `#7e7ea1`,
	"dark700": `#696992`,
	"dark800": `#535382`,
	"dark900": `#282863`,
	"green45": `#eff6fa`,
	"green50": `#f3fcfa`,
	"green300": `#b6ebdf`,
	"green500": `#86ddca`,
	"green700": `#56d0b5`,
	"green800": `#36b89b`,
	"green900": `#089475`,
	"orange50": `#fdf2dc`,
	"orange300": `#fadea8`,
	"orange500": `#f9d797`,
	"orange700": `#f7ca75`,
	"orange800": `#f5bd52`,
	"orange900": `#dd9306`,
	"purple50": `#e1d9fe`,
	"purple300": `#c3b3fc`,
	"purple500": `#a58efb`,
	"purple700": `#8768f9`,
	"purple800": `#6942f8`,
	"purple900": `#4520cc`,
	"red200": `#fdf0f0`,
	"red300": `#fee3e3`,
	"red500": `#f9d5d5`,
	"red700": `#f3aaaa`,
	"red900": `#eb7272`,
	"red950": `#e86161`,
	"red970": `#dd4c4c`,
	"white": `#fff`,
	"defaultTextStandard": `#282863`,
	"defaultTextSecondary": `#696992`,
	"defaultButtonPrimary": `#6942f8`,
	"buttonPrimary": `var(--button-primary, #6942f8)`,
	"buttonPrimaryDark": `var(--button-primary-dark, #4520cc)`,
	"buttonDisabled": `var(--button-disabled, #bfbfd0)`,
	"buttonDisabledDark": `var(--button-disabled-dark, #a9a9c1)`,
	"defaultButtonDisabled": `#bfbfd0`,
	"defaultStepperCompleted": `#089475`,
	"defaultStepperIncomplete": `#d4d4e0`,
	"defaultButtonSecondary": `#fcfcfd`,
	"defaultBgStandard": `#f3f5ff`,
	"defaultBgLight": `#fff`,
	"sh10": `0px 4px 12px rgba(40,40,99,.06)`,
	"sh20": `0px 4px 16px rgba(40,40,99,.08)`,
	"sh40": `0px 4px 24px rgba(40,40,99,.12)`,
	"sh60": `0px 4px 14px rgba(40,40,99,.24)`,
	"flexContainer": `gjFSb`,
	"toggle": `oOwnS`,
	"toggleIcon": `HJveS`,
	"header": `WOcb4`,
	"titleWrapper": `I58gk`,
	"progressBar": `JURVQ`,
	"stepBtn": `vLIUJ`,
	"selected": `vhIUG`,
	"stepBtnRight": `YPe7E`,
	"box": `o7yRx`,
	"stepLayout": `UeYai`,
	"checkmark": `Jyj0K`,
	"kycIncompleteBanner": `HjbGr`,
	"stepBodyContainer": `vV1LH`
};
export default ___CSS_LOADER_EXPORT___;
