import { userSelectors } from "./userSelectors";
import { ff } from "./ffSelectors";
import { filterAvailableRoutes } from "./filterAvailableRoutes";
import { integrationSelectors } from "./integrationSelectors";
import { KYBProgressStatusEnum } from 'anchor-kyb-service/types';
import { userModel } from "../../models";
export const selectors = {
  ff,
  user: userSelectors,
  integration: integrationSelectors,
  filterAvailableRoutes,
  impersonatingAs(state) {
    return state.claims ? state.claims.impersonate : null;
  },
  intercomUserHash(state) {
    return state.claims ? state.claims.intercomUserHash : null;
  },
  isResetPasswordFlow(_ref) {
    let {
      claims
    } = _ref;
    return claims ? claims.setPasswordOnly : false;
  },
  mgmtHost(state) {
    return state.session.mgmtHost;
  },
  pdfHost(state) {
    return state.session.pdfHost;
  },
  hasSentAProposal(state) {
    var _state$businessInfo;
    return ((_state$businessInfo = state.businessInfo) === null || _state$businessInfo === void 0 ? void 0 : _state$businessInfo.hasSentAProposal) || false;
  },
  showMissingColumnBankAccountBanner(s) {
    var _s$personaKYBStatus$d;
    const userInfo = s.userInfo;
    return !!userInfo && userModel.isVendor(userInfo) && (userModel.isOwner(userInfo) || userModel.isAdmin(userInfo)) && !userModel.paymentsDisabled(userInfo) && userModel.columnEntityCreated(userInfo) && userModel.hasColumnAccountId(userInfo) && userModel.hasColumnWalletAccountId(userInfo) && !userModel.hasColumnPayoutAccountId(userInfo) && ((_s$personaKYBStatus$d = s.personaKYBStatus.data) === null || _s$personaKYBStatus$d === void 0 ? void 0 : _s$personaKYBStatus$d.status) === KYBProgressStatusEnum.Approved;
  }
};