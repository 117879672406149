function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { ignoreError } from "../../infra/utils";
export const hubspot = {
  resetIdentity() {},
  identify(userId, userEmail, userProperties) {
    ignoreError(() => {
      var _window$_hsq;
      (_window$_hsq = window._hsq) === null || _window$_hsq === void 0 || _window$_hsq.push(['identify', _objectSpread({
        email: userEmail,
        userId: userId
      }, userProperties)]);
    });
  },
  sendEventDeprecated(eventName, eventData) {
    ignoreError(() => {
      const hsEventName = getHubspotEventName(eventName);
      if (hsEventName) {
        var _window$_hsq2;
        (_window$_hsq2 = window._hsq) === null || _window$_hsq2 === void 0 || _window$_hsq2.push(['trackCustomBehavioralEvent', {
          name: hsEventName,
          properties: eventData
        }]);
      }
    });
  },
  report(eventName, eventData) {
    ignoreError(() => {
      var _window$_hsq3;
      (_window$_hsq3 = window._hsq) === null || _window$_hsq3 === void 0 || _window$_hsq3.push(['trackCustomBehavioralEvent', {
        name: addPrefixToEventName(eventName),
        properties: eventData
      }]);
    });
  },
  sendPageView(url, _eventData) {
    ignoreError(() => {
      var _window$_hsq4, _window$_hsq5;
      (_window$_hsq4 = window._hsq) === null || _window$_hsq4 === void 0 || _window$_hsq4.push(['setPath', url]);
      (_window$_hsq5 = window._hsq) === null || _window$_hsq5 === void 0 || _window$_hsq5.push(['trackPageView']);
    });
  }
};
const hubspotEvents = {
  'form-success-general-logged-in-successfully': 'general_logged_in_successfully_',
  'form-success-onboarding-signed-up': 'onboarding_signed_up',
  'form-success-user-successfully-sent-first-agreement': 'user_successfully_sent_first_agreement_',
  'form-success-select-proposal-modal': 'select_proposal_modal_',
  'button-click-agreement-previewed-agreement': 'agreement_previewed_agreement',
  'form-success-send-agreement-for-approval': 'agreement_studio_custom_notification_modal_btn_su',
  'form-success-agreement-client-added-payment-method': 'agreement_client_added_payment_method_',
  'button-click-agreement-client-approved-agreement-secure-ach': 'agreement_client_approved_agreement_secure_ach_',
  'button-click-agreement-client-approved-agreement-credit-card': 'agreement_client_approved_agreement_credit_card_',
  'button-click-agreement-client-approved-agreement-micro-deposits': 'agreement_client_approved_agreement_micro_deposi',
  'button-click-agreement-client-approved-agreement-existing-method': 'agreement_client_approved_agreement_existing_met',
  'button-click-service-modal-btn-ok': 'service_modal_btn_ok_',
  'button-click-service-modal-btn-save-and-add-another': 'service_modal_btn_save_and_add_another_',
  'form-success-billing-hub-added-credit': 'billing_hub_added_credit_',
  'form-success-billing-hub-added-charge': 'billing_hub_added_charge_',
  'button-click-billing-hub-pause-service': 'billing_hub_pause_service_',
  'button-click-billing-hub-resume-service': 'billing_hub_resume_service_',
  'button-click-agreement-minisite-topbar-submit-termination': 'agreement_minisite_topbar_submit_termination_',
  'form-success-billing-hub-edited-agreement': 'billing_hub_edited_agreement_',
  'form-success-billing-hub-clicked-bill-now-bill-button': 'billing_hub_clicked_bill_now_bill_button_',
  'button-click-confirm-bill-now-button': 'confirm_bill_now_button_',
  'button-click-agreement-verified-micro-deposits': 'agreement_verified_micro_deposits_',
  'button-click-billing-hub-client-approved-amended-agreement-success': 'billing_hub_client_approved_amended_agreement_suc_',
  'button-click-billing-hub-client-approved-edited-services-success': 'billing_hub_client_approved_edited_services_succe_',
  'button-click-billing-hub-client-approved-payment': 'billing_hub_client_approved_payment_',
  'form-success-navbar-added-credit': 'navbar_added_credit_',
  'form-success-navbar-added-charge': 'navbar_added_charge_',
  'form-success-team-invited-member': 'team_invited_member_',
  'form-success-app-sign-up': 'app_sign_up_'
};

/** This list is the up-to-date one for the allowed events via reportToHubspot() in the code. If something is missing, add it here */

function getHubspotEventName(eventName) {
  const prefix = "pe".concat(window.hubspotEnvironment, "_");
  const hsEventName = hubspotEvents[eventName.toLowerCase()];
  return !!hsEventName && "".concat(prefix).concat(hsEventName);
}
const addPrefixToEventName = eventName => "pe".concat(window.hubspotEnvironment, "_").concat(eventName);