import Decimal from 'decimal.js';
import { fromDatestampArray } from "../time";
export function makeResponse(data) {
  return data || {};
}
export function make(billableState, periods, minDate, maxDate) {
  return {
    periods,
    minDate,
    maxDate,
    billableState
  };
}
export function makePeriod(startDate, endDate, billedQuantity, scheduledQuantity) {
  return {
    startDate: Array.isArray(startDate) ? fromDatestampArray(startDate) : startDate,
    endDate: Array.isArray(endDate) ? fromDatestampArray(endDate) : endDate,
    billedQuantity: new Decimal(billedQuantity),
    scheduledQuantity: new Decimal(scheduledQuantity)
  };
}