import { useStoreActions } from "../contexts";
export function useCreateRelationshipFromDraft() {
  const {
    api,
    markHasSentAProposal
  } = useStoreActions();
  return async draftId => {
    const relationshipId = await api.relationship.createFromDraft(draftId);
    markHasSentAProposal();
    return relationshipId;
  };
}