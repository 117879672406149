// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zGRjY{display:grid;grid-auto-rows:minmax(300px, 1fr);grid-auto-columns:300px;grid-auto-flow:column;gap:16px}.zGRjY.ILG0E{justify-content:center;grid-auto-flow:row}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"packagesList": `zGRjY`,
	"mobile": `ILG0E`
};
export default ___CSS_LOADER_EXPORT___;
