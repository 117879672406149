// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xn1U4{margin-bottom:16px}.yvsg2{display:flex;gap:8px}.KZwBN{display:flex;flex-wrap:wrap;align-items:center;gap:34px 16px}.lVgFS{width:fit-content}.vWB79{width:600px}.JlJ4g{display:flex;flex-direction:row;gap:8px;width:fit-content}.JlJ4g button{width:312px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sectionTitle": `xn1U4`,
	"actionButtonsContainer": `yvsg2`,
	"savedPaymentMethods": `KZwBN`,
	"accountantAccessCalloutGridItem": `lVgFS`,
	"accountantAccessCallout": `vWB79`,
	"accountantAccessCalloutContent": `JlJ4g`
};
export default ___CSS_LOADER_EXPORT___;
