import compact from 'lodash/compact';
import { Intent } from "../infra/access-control";
import { timeModel } from "../models";
import { getInvoiceQboErrorMessage } from "../utils/getQboErrorMessage";
export function getInvoiceQboActions(options) {
  const {
    integration,
    syncAction,
    retrySyncAction,
    mapContactsAction,
    mapServicesAction
  } = options;
  if (!integration) return [];
  const isPendingCustomerLinked = integration.isPendingCustomerLinked();
  const isPendingItemsLinked = integration.isPendingItemsLinked();
  const canBeSynced = integration.isPendingSync() && !isPendingCustomerLinked && !isPendingItemsLinked;
  return compact([integration.isSyncFailed() && retrySyncAction, isPendingCustomerLinked && mapContactsAction, isPendingItemsLinked && mapServicesAction, canBeSynced && syncAction]);
}
export function getInvoiceQboInfoStatus(integration) {
  const error = () => 'error';
  const success = () => 'success';
  return integration.switch({
    whenPendingSync: () => 'pending',
    whenInvoiceRecorded: success,
    whenVoidedRecorded: success,
    whenPaymentRecorded: success,
    whenRefundRecorded: success,
    whenInvoiceSyncFailed: error,
    whenPaymentSyncFailed: error,
    whenRefundSyncFailed: error
  });
}
export function makeRetrySyncAction(isSyncing, syncInvoice) {
  return {
    id: 'retry-sync-invoice',
    text: 'Retry sync',
    loading: isSyncing,
    loadingText: 'Syncing...',
    onClick: () => syncInvoice({
      isRetry: true
    })
  };
}
export function makeSyncAction(isSyncing, syncInvoice) {
  return {
    id: 'sync-invoice',
    text: 'Sync',
    loading: isSyncing,
    loadingText: 'Syncing...',
    onClick: () => syncInvoice()
  };
}
export function makeMapContactsAction(openSyncContactsModal) {
  return {
    id: 'map-contact',
    text: 'Map contact',
    onClick: openSyncContactsModal
  };
}
export function makeMapServicesAction(openSyncServicesModal) {
  return {
    id: 'map-services',
    text: 'Map services',
    intent: Intent.QboMapServices,
    onClick: openSyncServicesModal
  };
}
export function getInvoiceQboInfo(integration) {
  if (!integration) return '';
  return integration.switch({
    whenPendingSync: _ref => {
      let {
        customerLinked,
        itemsLinked
      } = _ref;
      if (!customerLinked && !itemsLinked) {
        return 'Pending contact and services mapping with QuickBooks Online.';
      }
      if (!customerLinked) return 'Pending contact mapping with QuickBooks Online customers.';
      if (!itemsLinked) return 'Pending services mapping with QuickBooks Online items.';
      return 'Invoice is ready to sync with QuickBooks Online.';
    },
    whenInvoiceRecorded: _ref2 => {
      let {
        date
      } = _ref2;
      return "Invoice recorded in QuickBooks Online on ".concat(timeModel.formatDateWithTime(date), ".");
    },
    whenVoidedRecorded: _ref3 => {
      let {
        date
      } = _ref3;
      return "Invoice voided status was updated in QuickBooks on ".concat(timeModel.formatDateWithTime(date), ".");
    },
    whenPaymentRecorded: _ref4 => {
      let {
        date
      } = _ref4;
      return "Payment recorded in QuickBooks Online on ".concat(timeModel.formatDateWithTime(date), ".");
    },
    whenRefundRecorded: _ref5 => {
      let {
        date
      } = _ref5;
      return "Refund recorded in QuickBooks Online on ".concat(timeModel.formatDateWithTime(date), ".");
    },
    whenInvoiceSyncFailed: () => getInvoiceQboErrorMessage(integration),
    whenPaymentSyncFailed: () => getInvoiceQboErrorMessage(integration),
    whenRefundSyncFailed: () => getInvoiceQboErrorMessage(integration)
  });
}