// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iQI2j{padding-top:16px}.MBhvK{display:flex;align-items:center;justify-content:flex-end;gap:24px}.IJLJO{display:flex;width:100%;gap:6px}.cO_mS{min-width:38px}.UvKol{margin-top:300px;text-align:center}._0Heo{flex:1;min-width:48px}.OW6Ng{display:flex;width:100%;gap:6px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `iQI2j`,
	"footer": `MBhvK`,
	"preapproved": `IJLJO`,
	"preapprovedInput": `cO_mS`,
	"loading": `UvKol`,
	"capUnitName": `_0Heo`,
	"priceLabel": `OW6Ng`
};
export default ___CSS_LOADER_EXPORT___;
