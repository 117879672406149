import { useGlobalContext } from 'seagull';
import { useHistory } from 'react-router-dom';
import { useStoreActions } from "../../contexts";
import { routes } from "../../constants";
export const useRedirectToProposal = () => {
  const history = useHistory();
  const globalContext = useGlobalContext();
  const {
    updateUserClientData,
    logBI
  } = useStoreActions();
  const redirectToProposal = async (proposalType, draftId, state) => {
    try {
      globalContext.startLoader('temp');
      await updateUserClientData({
        lastSelectedProposal: proposalType
      });
      globalContext.stopLoader('temp');
      const studioPath = draftId ? routes.draft(draftId) : routes.agreementStudio;
      switch (proposalType) {
        case 'agreement':
          logBI(10350, {});
          history.push(studioPath, state);
          break;
        case 'invite':
          logBI(10353, {});
          history.push("".concat(studioPath, "/existing-client"), state);
          break;
      }
    } catch (e) {
      globalContext.stopLoader('temp');
      globalContext.showErrorToast('Failed to create a proposal, please try again', 'create-proposal-error-toast');
    }
  };
  const openCreationModal = async (draftIdGetter, state) => {
    if (typeof draftIdGetter !== 'undefined') {
      const draftId = await draftIdGetter('agreement');
      await redirectToProposal('agreement', draftId, state);
    } else {
      await redirectToProposal('agreement', undefined, state);
    }
  };
  return {
    openCreationModal,
    redirectToProposal
  };
};