export const tutorialVideos = [{
  id: 'UUN10hsnHmc',
  duration: '1:20',
  title: 'Welcome to Anchor 💜'
}, {
  id: 'NR9JuT-VHYw',
  duration: '3:54',
  title: 'A Quick Demo for New Users'
}, {
  id: 'ctwHVOJGqVI',
  duration: '5:14',
  title: 'Site Tour'
}, {
  id: 'rOAunS9nvpU',
  duration: '1:12',
  title: 'Setting Up Your Account'
}, {
  id: 'QeIUKT8F9YM',
  duration: '5:01',
  title: 'Setting Up Your Service Library'
}, {
  id: 'H3j8sKUjv8U',
  duration: '5:50',
  title: 'Creating Proposals'
}, {
  id: '-wLAAwo2QyM',
  duration: '2:03',
  title: 'Editing an Agreement in Anchor'
}, {
  id: 'JSg1M-Hyqho',
  duration: '3:12',
  title: 'Billing Hub Overview'
}, {
  id: '0NexKPxUzsk',
  duration: '0:51',
  title: 'Adding Credit to Client Invoices'
}, {
  id: 'OMn0bWYNDuA',
  duration: '1:16',
  title: 'Adding Charges'
}, {
  id: 'lR4Vi-LdwEo',
  duration: '0:50',
  title: "Verifying a Client's Account using Micro-deposits"
}, {
  id: 'Es4cF0f6uQY',
  duration: '2:35',
  title: "Integrating QuickBooks Online with Anchor"
}, {
  id: '7yiqP8LWZuE',
  duration: '1:56',
  title: "Dashboard Overview"
}, {
  id: '1iw0OcLIKLE',
  duration: '0:58',
  title: "Updating Client Payment Methods"
}, {
  id: 'uqYsRRiTmsM',
  duration: '1:13',
  title: "How to Update Your Payment Method: A Guide for Anchor Clients"
}, {
  id: 'avvia6aRuyA',
  duration: '1:00',
  title: "Setting Up Accountant Access"
}];