function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import compact from 'lodash/compact';
import { netTermsOptions } from "../constants";
import { approvalMethodNoticePeriodModel, paymentMethodModel, relationshipModel, serviceCostModel, serviceModel } from "../models";
import { ServiceChangeItemType } from "../models/service/model";
import { utils } from "../utils";
import { getUnitName } from "../utils/unit-name.utils";
import { numberAndUnit } from "../infra/utils";
import isEqual from 'lodash/isEqual';
import { formatDate } from "../models/time";
export const netTermsSelectOptions = netTermsOptions.map(numOfDays => ({
  value: numOfDays,
  text: numOfDays === 0 ? 'on acceptance' : numberAndUnit(numOfDays, 'day')
}));
export function getAllowManualPaymentsChangeBullet(relationship, allowManualPayments) {
  return allowManualPayments === relationship.allowManualPayments ? null : {
    text: getAllowManualPaymentsChangeText(relationship.allowManualPayments, allowManualPayments),
    requiresApproval: !allowManualPayments
  };
}
export function getAllowManualPaymentsChangeText(origValue, newValue) {
  const from = getAllowManualPaymentsText(origValue);
  const to = getAllowManualPaymentsText(newValue);
  return "Manual approval of each payment - changed from \"".concat(from, "\" to \"").concat(to, "\"");
}
export function getAllowManualPaymentsText(allowManualPayments) {
  return allowManualPayments ? 'Required' : 'Not required';
}
export function getCoverCCFeesChangeBullet(relationship, coverCreditCardFees) {
  const origCoverCCFees = relationshipModel.isVendorCoveringCCFee(relationship);
  const isCreditCard = !!relationship.paymentMethod && paymentMethodModel.isCreditCard(relationship.paymentMethod);
  if (coverCreditCardFees === origCoverCCFees) {
    return null;
  } else {
    const vendorBusinessName = relationshipModel.vendorName(relationship);
    const clientBusinessName = relationshipModel.customerName(relationship);
    return {
      text: getCoverCCFeesChangeText(origCoverCCFees, coverCreditCardFees, vendorBusinessName, clientBusinessName),
      requiresApproval: isCreditCard && !coverCreditCardFees
    };
  }
}
export function getCoverCCFeesChangeText(oldCoverCCFees, newCoverCCFees, vendorBusinessName, clientBusinessName) {
  const from = getCoverCCFeesText(oldCoverCCFees, vendorBusinessName, clientBusinessName);
  const to = getCoverCCFeesText(newCoverCCFees, vendorBusinessName, clientBusinessName);
  return "Credit card processing fees - changed from \"".concat(from, "\" to \"").concat(to, "\"");
}
export function getCoverCCFeesText(coverCreditCardFees, vendorBusinessName, clientBusinessName) {
  const name = coverCreditCardFees ? "".concat(vendorBusinessName || 'service provider') : "".concat(clientBusinessName || 'client');
  return "Paid by ".concat(name);
}
export function getNetTermsChangeBullet(relationship, updatedNetTerms) {
  if (updatedNetTerms === relationship.netTerms) {
    return null;
  }
  const beforeText = netTermsSelectOptions.find(opt => opt.value === relationship.netTerms).text;
  const afterText = netTermsSelectOptions.find(opt => opt.value === updatedNetTerms).text;
  return {
    text: "Payment terms changed from ".concat(beforeText, " to ").concat(afterText),
    requiresApproval: updatedNetTerms < relationship.netTerms
  };
}
export function getInvoicesApprovalMethodChangeBullet(originalApprovalMethod, newApprovalMethod) {
  const originalNoticePeriod = approvalMethodNoticePeriodModel.noticePeriodDays(originalApprovalMethod);
  const newNoticePeriod = approvalMethodNoticePeriodModel.noticePeriodDays(newApprovalMethod);
  if (originalNoticePeriod != null) {
    if (newNoticePeriod != null) {
      return newNoticePeriod !== originalNoticePeriod ? {
        text: "Out of scope charges approval - notice period changed from ".concat(numberAndUnit(originalNoticePeriod, 'day'), " to ").concat(numberAndUnit(newNoticePeriod, 'day')),
        requiresApproval: newNoticePeriod < originalNoticePeriod
      } : null;
    } else {
      return {
        text: 'Out of scope charges approval - changed from auto-approval with a notice period to manual approval',
        requiresApproval: false
      };
    }
  } else {
    if (newNoticePeriod != null) {
      return {
        text: "Out of scope charges approval - changed from manual to auto-approval with a notice period of ".concat(numberAndUnit(newNoticePeriod, 'day')),
        requiresApproval: true
      };
    } else {
      return null;
    }
  }
}
export function getAmendmentsApprovalMethodChangeBullet(originalApprovalMethod, newApprovalMethod) {
  const originalNoticePeriod = approvalMethodNoticePeriodModel.noticePeriodDays(originalApprovalMethod);
  const newNoticePeriod = approvalMethodNoticePeriodModel.noticePeriodDays(newApprovalMethod);
  if (originalNoticePeriod != null) {
    if (newNoticePeriod != null) {
      return newNoticePeriod !== originalNoticePeriod ? {
        text: "Amendments approval - notice period changed from ".concat(numberAndUnit(originalNoticePeriod, 'day'), " to ").concat(numberAndUnit(newNoticePeriod, 'day')),
        requiresApproval: newNoticePeriod < originalNoticePeriod
      } : null;
    } else {
      return {
        text: 'Amendments approval - changed from auto-approval with a notice period to manual approval',
        requiresApproval: false
      };
    }
  } else {
    if (newNoticePeriod != null) {
      return {
        text: "Amendments approval - changed from manual to auto-approval with a notice period of ".concat(numberAndUnit(newNoticePeriod, 'day')),
        requiresApproval: true
      };
    } else {
      return null;
    }
  }
}
export function getServiceChangeBullets(service) {
  if (serviceModel.isAwaitingApproval(service.status)) {
    return [{
      text: 'A new service has been added',
      requiresApproval: true
    }];
  }
  return serviceModel.makeServiceChangeList(service).map(item => {
    switch (item.type) {
      case ServiceChangeItemType.Description:
        {
          return {
            text: 'Service description was changed',
            requiresApproval: item.requiresApproval
          };
        }
      case ServiceChangeItemType.Discount:
        {
          var _service$cost$discoun, _item$value;
          const noDiscount = 'none';
          const fromDiscount = ((_service$cost$discoun = service.cost.discount) === null || _service$cost$discoun === void 0 ? void 0 : _service$cost$discoun.format()) || noDiscount;
          const toDiscount = ((_item$value = item.value) === null || _item$value === void 0 ? void 0 : _item$value.format()) || noDiscount;
          return {
            text: "Discount was changed from ".concat(fromDiscount, " to ").concat(toDiscount),
            requiresApproval: item.requiresApproval
          };
        }
      case ServiceChangeItemType.BillingTrigger:
        {
          const oldBillingCycle = getBillingCycle(service.billing);
          const newBillingCycle = getBillingCycle(item.value);
          const oldBillingMode = getBillingMode(service.billing);
          const newBillingMode = getBillingMode(item.value);
          const oldBillingDayOfMonth = serviceModel.billingDayOfMonth(service);
          const newBillingDayOfMonth = serviceModel.billingDayOfMonth({
            billing: item.value
          });
          const oldBillingDayOfWeek = serviceModel.billingDayOfWeek(service);
          const newBillingDayOfWeek = serviceModel.billingDayOfWeek({
            billing: item.value
          });
          return compact([oldBillingCycle !== newBillingCycle && {
            text: "Billing cycle was changed from ".concat(oldBillingCycle, " to ").concat(newBillingCycle),
            requiresApproval: item.requiresApproval
          }, oldBillingMode !== newBillingMode && {
            text: "Billing trigger was changed from ".concat(oldBillingMode, " to ").concat(newBillingMode),
            requiresApproval: false,
            emailWontBeSent: true
          }, oldBillingDayOfMonth != null && newBillingDayOfMonth != null && !isEqual(oldBillingDayOfMonth, newBillingDayOfMonth) && {
            text: "Billing day - changed from ".concat(oldBillingDayOfMonth.formatDay(), " to ").concat(newBillingDayOfMonth.formatDay()),
            requiresApproval: false
          }, oldBillingDayOfWeek != null && newBillingDayOfWeek != null && oldBillingDayOfWeek !== newBillingDayOfWeek && {
            text: "Billing day - changed from ".concat(oldBillingDayOfWeek, " to ").concat(newBillingDayOfWeek),
            requiresApproval: false
          }]);
        }
      case ServiceChangeItemType.Pricing:
        {
          const originalPrice = getPrice(service.cost);
          const newPrice = getPrice(_objectSpread(_objectSpread({}, item.value), {}, {
            discount: null
          }));
          return compact([originalPrice !== newPrice && {
            text: "Price was changed from ".concat(originalPrice, " to ").concat(newPrice),
            requiresApproval: item.requiresApproval
          }]);
        }
      case ServiceChangeItemType.PriceIncrease:
        {
          const prevPriceIncrease = serviceModel.priceIncrease(service);
          const text = prevPriceIncrease && item.value ? "Automatic price increase was changed from ".concat(getPriceIncreaseText(prevPriceIncrease), " to ").concat(getPriceIncreaseText(item.value)) : prevPriceIncrease ? "Automatic price increase of ".concat(getPriceIncreaseText(prevPriceIncrease), " was removed") : item.value ? "Automatic price increase of ".concat(getPriceIncreaseText(item.value), " was added") : '';
          return {
            text,
            requiresApproval: item.requiresApproval
          };
        }
    }
  }).flat(1);
}
function getPriceIncreaseText(priceIncrease) {
  return "".concat(serviceModel.isInvalidPercentIncrease(priceIncrease.percentIncrease) ? '' : priceIncrease.percentIncrease, "% every year ").concat(priceIncrease.increaseOn ? "starting on ".concat(formatDate(priceIncrease.increaseOn)) : 'on agreement anniversary');
}
function getBillingCycle(billing) {
  return serviceModel.isRecurring(billing) ? serviceModel.recurrencePeriod(billing) : 'one time';
}
function getBillingMode(billing) {
  return serviceModel.isAutomatic(billing) ? 'automatic' : 'manual';
}
function getPrice(cost) {
  if (serviceCostModel.isFixedPrice(cost)) {
    return utils.formatDollarAmount(cost.price);
  }
  if (serviceCostModel.isRangePrice(cost)) {
    const min = utils.formatDollarAmount(cost.minPrice || 0);
    const max = utils.formatDollarAmount(cost.maxPrice);
    return "a range from ".concat(min, " to ").concat(max);
  }
  return compact(["".concat(utils.formatDollarAmount(cost.price), "/").concat(getUnitName(cost.unitName)), cost.unitCap && "(".concat(utils.formatNumberWithCommas(cost.unitCap), " preapproved)")]).join(' ');
}