import { getMatchingPaymentStatusFilter, InvoiceHeaderItemID, noPaymentStatus } from "../../../components/InvoicesTable/InvoicesTable.utils";
import { getSortUrlParamValue, sortUrlParam } from "../../../hooks/useSortFromUrl";
import { routes } from "../../../constants";
import Decimal from 'decimal.js';
import { utils } from "../../../utils";
import { InvoiceDisplayStatus, InvoicePaymentDisplayStatusType } from 'anchor-scout/types';
export const chartDataForLoading = [{
  type: 'paymentIssues',
  requireAttention: 3,
  expectedToBePaid: 0,
  totals: {
    requireAttention: 3,
    expectedToBePaid: 0
  }
}, {
  type: 'pendingClientApproval',
  requireAttention: 8,
  expectedToBePaid: 0,
  totals: {
    requireAttention: 8,
    expectedToBePaid: 0
  }
}, {
  type: 'expectedToBePaid',
  requireAttention: 0,
  expectedToBePaid: 16,
  totals: {
    requireAttention: 0,
    expectedToBePaid: 16
  }
}];
const paymentIssuesStatuses = new Set([InvoicePaymentDisplayStatusType.PaymentFailed, InvoicePaymentDisplayStatusType.StoppedPayment]);
const expectedToBePaidInvoiceStatuses = new Set([InvoiceDisplayStatus.Issued, InvoiceDisplayStatus.PaymentInitiated]);
const expectedToBePaidPaymentStatuses = new Set([InvoicePaymentDisplayStatusType.Processing, InvoicePaymentDisplayStatusType.Collected, InvoicePaymentDisplayStatusType.Pending, noPaymentStatus]);
const pendingClientApprovalStatuses = new Set([InvoicePaymentDisplayStatusType.RequiresPaymentApproval, InvoicePaymentDisplayStatusType.RequiresPaymentValidation]);
const missingPMStatuses = new Set([InvoicePaymentDisplayStatusType.PaymentMethodUnverified, InvoicePaymentDisplayStatusType.PaymentMethodNotSet]);
const getTotalAmount = invoices => invoices.reduce((result, inv) => result.plus(inv.rawTotalAmount), new Decimal(0)).toDP(2).toNumber();
const hasPaymentIssues = inv => inv.paymentStatus && paymentIssuesStatuses.has(inv.paymentStatus.type);
const isInvoicePendingClientApproval = inv => inv.paymentStatus && pendingClientApprovalStatuses.has(inv.paymentStatus.type);
const isInvoiceExpectedToBePaid = inv => expectedToBePaidInvoiceStatuses.has(inv.displayedStatus) && expectedToBePaidPaymentStatuses.has(getMatchingPaymentStatusFilter(inv));
const isMissingPaymentMethod = inv => inv.paymentStatus && missingPMStatuses.has(inv.paymentStatus.type);
export const getInvoicesWidgetData = invoices => {
  if (!invoices) {
    return null;
  }
  const paymentIssues = invoices.filter(hasPaymentIssues);
  const pendingClientApproval = invoices.filter(isInvoicePendingClientApproval);
  const missingPaymentMethod = invoices.filter(isMissingPaymentMethod);
  const requireAttention = paymentIssues.concat(pendingClientApproval).concat(missingPaymentMethod);
  const expectedToBePaid = invoices.filter(isInvoiceExpectedToBePaid);
  const expectedToBePaidTotal = getTotalAmount(expectedToBePaid);
  const result = {
    chart: [],
    totals: {
      requireAttention: {
        count: requireAttention.length,
        total: getTotalAmount(requireAttention)
      },
      expectedToBePaid: {
        count: expectedToBePaid.length,
        total: expectedToBePaidTotal
      }
    }
  };
  if (paymentIssues.length) {
    result.chart.push({
      type: 'paymentIssues',
      requireAttention: paymentIssues.length,
      expectedToBePaid: 0,
      totals: {
        requireAttention: getTotalAmount(paymentIssues),
        expectedToBePaid: 0
      }
    });
  }
  if (pendingClientApproval.length) {
    result.chart.push({
      type: 'pendingClientApproval',
      requireAttention: pendingClientApproval.length,
      expectedToBePaid: 0,
      totals: {
        requireAttention: getTotalAmount(pendingClientApproval),
        expectedToBePaid: 0
      }
    });
  }
  if (missingPaymentMethod.length) {
    result.chart.push({
      type: 'missingPaymentMethod',
      requireAttention: missingPaymentMethod.length,
      expectedToBePaid: 0,
      totals: {
        requireAttention: getTotalAmount(missingPaymentMethod),
        expectedToBePaid: 0
      }
    });
  }
  if (expectedToBePaid.length) {
    result.chart.push({
      type: 'expectedToBePaid',
      requireAttention: 0,
      expectedToBePaid: expectedToBePaid.length,
      totals: {
        requireAttention: 0,
        expectedToBePaid: expectedToBePaidTotal
      }
    });
  }
  return result;
};
export function getBarLinkUrl(data) {
  const params = new URLSearchParams();
  const paymentStatusFilter = 'paymentStatus';
  const invoiceStatusFilter = 'status';
  function setFilter(name, values) {
    params.set(name, Array.from(values).join(','));
  }
  function setSort(headerItemId) {
    params.set(sortUrlParam, getSortUrlParamValue(headerItemId, 'desc'));
  }
  if (data.type === 'paymentIssues') {
    setFilter(paymentStatusFilter, paymentIssuesStatuses);
    setSort(InvoiceHeaderItemID.status);
  } else if (data.type === 'pendingClientApproval') {
    setFilter(paymentStatusFilter, pendingClientApprovalStatuses);
    setSort(InvoiceHeaderItemID.issueDate);
  } else if (data.type === 'missingPaymentMethod') {
    setFilter(paymentStatusFilter, missingPMStatuses);
    setSort(InvoiceHeaderItemID.issueDate);
  } else if (data.type === 'expectedToBePaid') {
    setFilter(invoiceStatusFilter, expectedToBePaidInvoiceStatuses);
    setFilter(paymentStatusFilter, expectedToBePaidPaymentStatuses);
    setSort(InvoiceHeaderItemID.status);
  }
  return "".concat(routes.invoicesDashboard, "?").concat(params);
}
export const statusToText = {
  paymentIssues: 'Payment issues',
  pendingClientApproval: 'Client approval required',
  missingPaymentMethod: 'Missing Payment Method',
  expectedToBePaid: 'Expected to be paid'
};
export function getTooltipItems(payload) {
  return payload === null || payload === void 0 ? void 0 : payload.map(p => {
    const data = p.payload;
    const dataKey = p.dataKey || '';
    return {
      id: p.dataKey,
      label: utils.formatDollarAmount(data.totals[dataKey]),
      inBrackets: utils.formatNumberWithCommas(data[dataKey]),
      color: p.color,
      dataKey: p.dataKey
    };
  });
}