import { videoPlayerBorderRadius } from "../VideoPlayer.utils";
export function modifyLoomPlayerStyles(loomPlayerHtml) {
  const loomPlayerElement = document.createElement('div');
  loomPlayerElement.innerHTML = loomPlayerHtml;
  const playerDivContainer = loomPlayerElement.querySelector('.lo-emb-vid');
  const playerIframe = loomPlayerElement.querySelector('iframe');
  if (playerIframe && playerDivContainer) {
    playerDivContainer.style.paddingBottom = '';
    playerDivContainer.style.height = '100%';
    playerDivContainer.style.width = '100%';
    playerIframe.style.position = '';
    playerIframe.style.width = '100%';
    playerIframe.style.height = '100%';
    playerIframe.style.borderRadius = videoPlayerBorderRadius;
  }
  return loomPlayerElement.innerHTML;
}