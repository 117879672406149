import { useIoContext, useStore, useStoreActions, useStoreStatic } from "../contexts";
import { useOnMount } from "./useOnMount";
export function useFetchOnMountIfDataStale(getLoadableContent, fetch) {
  let expiry = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 30000;
  const {
    getState
  } = useStoreStatic();
  const {
    getNow
  } = useIoContext();
  const actions = useStoreActions();
  const loadableContent = useStore(getLoadableContent);
  useOnMount(() => {
    const {
      lastSuccessfulFetch
    } = getLoadableContent(getState());
    if (lastSuccessfulFetch == null || getNow().getTime() > lastSuccessfulFetch + expiry) {
      fetch(actions);
    }
  });
  return loadableContent;
}