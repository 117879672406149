// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MoTq5{display:flex;flex-direction:column;align-items:stretch;gap:32px}.iP6HK{display:flex;flex-direction:column;align-items:center}.RZMbo{width:400px;display:flex;flex-direction:column;align-items:center;gap:20px}.m1HQv{display:flex;flex-direction:column;gap:24px;text-align:start}.LlIOu{text-align:start}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `MoTq5`,
	"formContainer": `iP6HK`,
	"formComponentsWrapper": `RZMbo`,
	"textContainer": `m1HQv`,
	"secureLoginModalContainer": `LlIOu`
};
export default ___CSS_LOADER_EXPORT___;
