// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jo1jV{width:288px;height:300px;padding-top:32px;padding-bottom:16px;display:flex;flex-direction:column;align-items:center;justify-content:space-between}.XV0jM{padding:0 24px;min-height:64px}.Pvf10{padding:0 24px;text-align:center}.qv5H8{min-height:58px;display:flex;flex-direction:column;align-items:center;gap:8px}.XfETR{display:grid;grid-template-columns:repeat(auto-fill, 288px);gap:16px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `jo1jV`,
	"icon": `XV0jM`,
	"text": `Pvf10`,
	"bottom": `qv5H8`,
	"cardsContainer": `XfETR`
};
export default ___CSS_LOADER_EXPORT___;
