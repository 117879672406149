import { Chance } from 'chance';
const chance = new Chance();
export function AutocompletePredictionBuilder() {
  const main_text = chance.word();
  const secondary_text = chance.word();
  const prediction = {
    description: "".concat(main_text, " ").concat(secondary_text),
    matched_substrings: [],
    place_id: chance.guid(),
    structured_formatting: {
      main_text,
      main_text_matched_substrings: [],
      secondary_text
    },
    terms: [],
    types: []
  };
  const builder = {
    build: () => prediction,
    withDescription(mainText, secondaryText) {
      prediction.structured_formatting.main_text = mainText;
      prediction.structured_formatting.secondary_text = secondaryText;
      prediction.description = "".concat(mainText, " ").concat(secondaryText);
      return builder;
    }
  };
  return builder;
}
export function PlaceResultBuilder() {
  const placeResult = {};
  const builder = {
    build: () => placeResult,
    withAddressComponents(address_components) {
      placeResult.address_components = address_components;
      return builder;
    }
  };
  return builder;
}
export function AddressComponentBuilder() {
  const addressComponent = {
    long_name: chance.word(),
    short_name: chance.word(),
    types: []
  };
  const builder = {
    build: () => addressComponent,
    withStreetNumber(name) {
      addressComponent.long_name = name;
      addressComponent.types = ['street_number'];
      return builder;
    },
    withRoute(name) {
      addressComponent.long_name = name;
      addressComponent.types = ['route'];
      return builder;
    },
    withLocality(name) {
      addressComponent.long_name = name;
      addressComponent.types = ['locality'];
      return builder;
    },
    withAdministrativeAreaLevel1(name) {
      addressComponent.short_name = name;
      addressComponent.types = ['administrative_area_level_1'];
      return builder;
    },
    withCountry(name) {
      addressComponent.short_name = name;
      addressComponent.types = ['country'];
      return builder;
    },
    withPostalCode(name) {
      addressComponent.long_name = name;
      addressComponent.types = ['postal_code'];
      return builder;
    }
  };
  return builder;
}