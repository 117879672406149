import { nameValidation } from 'validators';
import { findDuplicates } from "../infra/utils";
import { validateContact } from "./validators";
export const reviewerNameValidator = nameValidation('', {
  required: true
});
export const reviewerEmailValidator = allRecipientsEmail => ({
  validate: {
    email: validateContact.email,
    unique: value => {
      if (typeof value === 'string') {
        if (allRecipientsEmail.map(r => r.email).includes(undefined)) {
          return false;
        }
        const lowerCasedRecipientsEmail = allRecipientsEmail.map(r => r.email.toLowerCase());
        const duplicates = findDuplicates(lowerCasedRecipientsEmail);
        const hasDuplicates = duplicates.length >= 1;
        return hasDuplicates ? 'Email already added' : true;
      } else {
        return false;
      }
    }
  }
});
export function validateReviewers(reviewers, primaryContact) {
  const primaryAndReviewers = (primaryContact ? [{
    email: primaryContact.email()
  }] : []).concat(reviewers.map(r => ({
    email: r.email || ''
  })));
  const emailValidator = reviewerEmailValidator(primaryAndReviewers);
  return reviewers.every(r => reviewerNameValidator.validate.name(r.firstName) === true && reviewerNameValidator.validate.name(r.lastName) === true && emailValidator.validate.email(r.email) === true && emailValidator.validate.unique(r.email) === true);
}