// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Wa47T{display:flex;flex-direction:column;align-items:flex-start}.zLyow{margin:6px 0}.KzjK3{gap:2px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": `Wa47T`,
	"option": `zLyow`,
	"textButton": `KzjK3`
};
export default ___CSS_LOADER_EXPORT___;
