import { IntroVideoType } from "./model";
export function makeIntroVideoNone() {
  return {
    type: IntroVideoType.None
  };
}
export function makeIntroVideoLink(url) {
  return {
    type: IntroVideoType.VideoLink,
    videoLink: {
      url
    }
  };
}