// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.o1SZ4{width:100%;height:100%;max-height:100%;background:linear-gradient(270deg, var(--bg-gradient-color-1, #e5f3fe) 0%, var(--bg-gradient-color-2, #ebebfd) 100%);box-shadow:0px 4px 12px rgba(40,40,99,.06);display:grid;grid-template-rows:1fr auto}.GmC39{position:absolute;top:12px;right:12px;width:245px}.JFDFb{display:grid;grid-template-rows:auto 1fr;overflow:hidden}.p_PMh{padding:20px;display:flex}.iExff{max-width:1000px;margin:0 20px}.f9DVm{border-radius:40px 0 0 0}.f9DVm .p_PMh{padding:40px 0 8px 40px;align-items:center;gap:12px}.f9DVm .bp5_7{overflow:auto}.f9DVm .iExff{margin:0 56px 0 40px}.f9DVm .vwwTN{max-width:1000px;padding:32px 56px 32px 40px;box-sizing:content-box;display:flex;flex-direction:row-reverse;justify-content:space-between;align-items:center}.f9DVm .wn7QP{width:300px}.f9DVm .MidSD{margin-top:6px}.f9DVm .wqg9K{display:flex;flex-direction:column;align-items:flex-end}.nrm2e .JFDFb{overflow:auto}.nrm2e .p_PMh{flex-direction:column-reverse;align-items:start;gap:24px}.nrm2e .bp5_7{min-height:100%}.nrm2e .iExff{min-height:100%}.nrm2e .vwwTN{padding:12px 20px 24px 20px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blue45": `#fcfcff`,
	"blue48": `#f5f7fd`,
	"blue50": `#f3f5ff`,
	"blue300": `#dae0ff`,
	"blue500": `#c0caff`,
	"blue600": `#a1aef5`,
	"blue700": `#8296ff`,
	"blue800": `#6176e7`,
	"dark50": `#fcfcfd`,
	"dark70": `#f4f4f7`,
	"dark100": `#eaeaef`,
	"dark120": `#e4e4ec`,
	"dark200": `#d4d4e0`,
	"dark300": `#bfbfd0`,
	"dark400": `#a9a9c1`,
	"dark500": `#9393b1`,
	"dark600": `#7e7ea1`,
	"dark700": `#696992`,
	"dark800": `#535382`,
	"dark900": `#282863`,
	"green45": `#eff6fa`,
	"green50": `#f3fcfa`,
	"green300": `#b6ebdf`,
	"green500": `#86ddca`,
	"green700": `#56d0b5`,
	"green800": `#36b89b`,
	"green900": `#089475`,
	"orange50": `#fdf2dc`,
	"orange300": `#fadea8`,
	"orange500": `#f9d797`,
	"orange700": `#f7ca75`,
	"orange800": `#f5bd52`,
	"orange900": `#dd9306`,
	"purple50": `#e1d9fe`,
	"purple300": `#c3b3fc`,
	"purple500": `#a58efb`,
	"purple700": `#8768f9`,
	"purple800": `#6942f8`,
	"purple900": `#4520cc`,
	"red200": `#fdf0f0`,
	"red300": `#fee3e3`,
	"red500": `#f9d5d5`,
	"red700": `#f3aaaa`,
	"red900": `#eb7272`,
	"red950": `#e86161`,
	"red970": `#dd4c4c`,
	"white": `#fff`,
	"defaultTextStandard": `#282863`,
	"defaultTextSecondary": `#696992`,
	"defaultButtonPrimary": `#6942f8`,
	"buttonPrimary": `var(--button-primary, #6942f8)`,
	"buttonPrimaryDark": `var(--button-primary-dark, #4520cc)`,
	"buttonDisabled": `var(--button-disabled, #bfbfd0)`,
	"buttonDisabledDark": `var(--button-disabled-dark, #a9a9c1)`,
	"defaultButtonDisabled": `#bfbfd0`,
	"defaultStepperCompleted": `#089475`,
	"defaultStepperIncomplete": `#d4d4e0`,
	"defaultButtonSecondary": `#fcfcfd`,
	"defaultBgStandard": `#f3f5ff`,
	"defaultBgLight": `#fff`,
	"sh10": `0px 4px 12px rgba(40,40,99,.06)`,
	"sh20": `0px 4px 16px rgba(40,40,99,.08)`,
	"sh40": `0px 4px 24px rgba(40,40,99,.12)`,
	"sh60": `0px 4px 14px rgba(40,40,99,.24)`,
	"defaultBgGradientColor1": `#e5f3fe`,
	"defaultBgGradientColor2": `#ebebfd`,
	"root": `o1SZ4`,
	"setupBrandContainer": `GmC39`,
	"headerAndContent": `JFDFb`,
	"header": `p_PMh`,
	"content": `iExff`,
	"desktop": `f9DVm`,
	"contentWrapper": `bp5_7`,
	"footer": `vwwTN`,
	"ctaWrapper": `wn7QP`,
	"disabledReason": `MidSD`,
	"primaryBtnContainer": `wqg9K`,
	"mobile": `nrm2e`
};
export default ___CSS_LOADER_EXPORT___;
