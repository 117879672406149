// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HqXki{display:flex;flex-direction:column;gap:8px;align-items:center}.q1jMv{padding:16px 12px}.YKfIH{margin-left:4px;display:flex;flex-direction:column;gap:4px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topCard": `HqXki`,
	"table": `q1jMv`,
	"viewMore": `YKfIH`
};
export default ___CSS_LOADER_EXPORT___;
