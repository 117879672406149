import { useEffect, useMemo } from 'react';
import debounce from 'lodash/debounce';
import { useStore, useStoreActions } from "../contexts";
import { useStateInRef } from 'seagull';
export const defaultDebounceTime = 1000;

/** A hook that gives convenience methods for logging searchbox changes to BI with a debounce. */
export const useDebouncedLogSearchToBI = (id, debounceTime) => {
  var _ref;
  const {
    logBI
  } = useStoreActions();
  const inputDebounceTime = useStore(state => state.appConfig.inputDebounceTime);
  const actualDebounceTime = (_ref = debounceTime !== null && debounceTime !== void 0 ? debounceTime : inputDebounceTime) !== null && _ref !== void 0 ? _ref : defaultDebounceTime;
  const debouncedLogSearchToBI = useMemo(() => debounce(search => logBI(id, {
    search
  }), actualDebounceTime), [id, actualDebounceTime]);
  const getDebouncedLogSearchToBI = useStateInRef(debouncedLogSearchToBI);

  // No need to report a search event if leaving the page before viewing the results.
  useEffect(() => {
    return () => {
      getDebouncedLogSearchToBI().cancel();
    };
  }, []);
  return useMemo(() => ({
    /** Fire this when the searchbox fires its onChange callback. */
    onChange(search) {
      if (search) {
        getDebouncedLogSearchToBI()(search);
      } else {
        // We don't want to report a search event when clearing.
        getDebouncedLogSearchToBI().cancel();
      }
    },
    /** Fire this when the searchbox fires its onClear callback. */
    onClear() {
      // We don't want to report a search event when clearing.
      getDebouncedLogSearchToBI().cancel();
    }
  }), []);
};