import Decimal from 'decimal.js';
import { IncreaseMethodType, IncreaseOnType, PriceIncreaseType } from "./model";
import { fromDatestampObject, toDatestampObject } from "../time";
export function fromDTO(dto) {
  switch (dto.type) {
    case PriceIncreaseType.None:
      return null;
    case PriceIncreaseType.Automatic:
      return {
        roundPrices: dto.automatic.roundPrices,
        percentIncrease: new Decimal(dto.automatic.increaseMethod.percent),
        increaseOn: dto.automatic.increaseOn.periodType === IncreaseOnType.Yearly ? fromDatestampObject(dto.automatic.increaseOn.yearly) : null
      };
  }
}
export function toDTO(increase) {
  if (!increase) {
    return {
      type: PriceIncreaseType.None
    };
  }
  return {
    type: PriceIncreaseType.Automatic,
    automatic: {
      roundPrices: increase.roundPrices,
      increaseMethod: {
        type: IncreaseMethodType.Percent,
        percent: increase.percentIncrease.toString()
      },
      increaseOn: increase.increaseOn ? {
        periodType: IncreaseOnType.Yearly,
        yearly: toDatestampObject(increase.increaseOn)
      } : {
        periodType: IncreaseOnType.EffectiveDate
      }
    }
  };
}
export function toDB(increase) {
  if (!increase) {
    return {
      type: PriceIncreaseType.None
    };
  }
  return {
    type: PriceIncreaseType.Automatic,
    automatic: {
      roundPrices: increase.roundPrices,
      increaseMethod: {
        type: IncreaseMethodType.Percent,
        percent: increase.percentIncrease.mul(100).toDP(0).toNumber()
      },
      increaseOn: increase.increaseOn ? {
        periodType: IncreaseOnType.Yearly,
        yearly: toDatestampObject(increase.increaseOn)
      } : {
        periodType: IncreaseOnType.EffectiveDate
      }
    }
  };
}