export function formatShortUSDate(date) {
  return date.toLocaleString('en-us', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  });
}
export function formatMediumUSDate(date) {
  let timeZone = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'UTC';
  return date.toLocaleString('en-us', {
    timeZone,
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
}