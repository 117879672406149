import { useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
export const sortUrlParam = 'sort';
export function getSortUrlParamValue(sortId, type) {
  return "".concat(sortId, ",").concat(type);
}

/**
 * Manages sort state from the URL search string.
 * Expects a query string like this - ?sort={column ID},{'asc'|'desc'}
 */
export const useSortFromUrl = options => {
  const {
    defaultSort,
    sortIds
  } = options;
  const location = useLocation();
  const history = useHistory();
  const sort = useMemo(() => {
    const urlSort = new URLSearchParams(location.search).get(sortUrlParam);
    if (!urlSort) {
      return defaultSort;
    }
    const [field, direction] = urlSort.split(',');
    const colIndex = sortIds.indexOf(field);
    if (colIndex === -1) {
      return defaultSort;
    }
    return {
      colIndex,
      sort: direction
    };
  }, [location.search, defaultSort, sortIds]);
  const changeSort = sort => {
    const params = new URLSearchParams(location.search);
    if (sort) {
      params.set(sortUrlParam, getSortUrlParamValue(sortIds[sort.colIndex], sort.sort));
    } else {
      params.delete(sortUrlParam);
    }
    history.replace("".concat(location.pathname, "?").concat(params.toString()));
  };
  return {
    sort,
    changeSort
  };
};