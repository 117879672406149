// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.G0DzO>*{margin-block-end:16px}.RT4pK{margin-block-end:16px}.dElls{margin-block-end:8px}.hT153{margin-block-start:48px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"callouts": `G0DzO`,
	"paragraph": `RT4pK`,
	"tableTitle": `dElls`,
	"secondTableTitle": `hT153`
};
export default ___CSS_LOADER_EXPORT___;
