import { ignoreError } from "../../infra/utils";
export const heap = {
  resetIdentity() {
    ignoreError(() => {
      var _window$heap;
      (_window$heap = window.heap) === null || _window$heap === void 0 || _window$heap.resetIdentity();
    });
  },
  identify(userId, _userEmail, userProperties) {
    ignoreError(() => {
      var _window$heap2, _window$heap3;
      (_window$heap2 = window.heap) === null || _window$heap2 === void 0 || _window$heap2.identify(userId);
      (_window$heap3 = window.heap) === null || _window$heap3 === void 0 || _window$heap3.addUserProperties(userProperties);
    });
  },
  sendEventDeprecated(eventName, eventData) {
    ignoreError(() => {
      var _window$heap4;
      (_window$heap4 = window.heap) === null || _window$heap4 === void 0 || _window$heap4.track(eventName, eventData);
    });
  },
  report(location, action, eventName, eventData) {
    ignoreError(() => {
      var _window$heap5;
      const eventNameWithProperties = getEventName(location, action, eventName);
      (_window$heap5 = window.heap) === null || _window$heap5 === void 0 || _window$heap5.track(eventNameWithProperties, eventData);
    });
  }
};
const getEventName = (location, action, eventName) => "".concat(location, " - ").concat(action, " - ").concat(eventName);