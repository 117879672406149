import { numberAndUnit } from "../../infra/utils";
import { ApprovalMethodNoticePeriodType } from "./model";
export function isAutoApproval(approvalMethod) {
  return approvalMethod.type === ApprovalMethodNoticePeriodType.Automatic;
}
export function noticePeriodDays(amendmentsApprovalMethod) {
  return isAutoApproval(amendmentsApprovalMethod) ? amendmentsApprovalMethod.auto.noticePeriodDays : null;
}
export function isEqual(a, b) {
  return isAutoApproval(a) === isAutoApproval(b) && noticePeriodDays(a) === noticePeriodDays(b);
}
export const amendmentsDescription = amendmentsApprovalMethod => isAutoApproval(amendmentsApprovalMethod) ? "Updates to this agreement will be sent via email and automatically approved after ".concat(numberAndUnit(amendmentsApprovalMethod.auto.noticePeriodDays, 'day'), " unless approved earlier or if auto-approval is disabled.") : 'Any change to the agreement will require your approval.';
export const invoicesDescription = invoicesApprovalMethod => isAutoApproval(invoicesApprovalMethod) ? "Charges outside the scope of this agreement will be sent via email and automatically approved after ".concat(numberAndUnit(invoicesApprovalMethod.auto.noticePeriodDays, 'day'), " unless approved earlier or if auto-approval is disabled") : 'You will be notified of any out of scope charges via email.';