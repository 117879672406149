import { useGlobalContext } from 'seagull';
import { useState } from 'react';
import { useIoContext, useStoreActions } from "../../contexts";
const loaderName = 'open-pdf';
export function useDownloadInvoicePdf() {
  const {
    api,
    logBI
  } = useStoreActions();
  const [downloading, setDownloading] = useState(false);
  const globalContext = useGlobalContext();
  const {
    openNewTab
  } = useIoContext();
  return {
    isDownloadingInvoicePdf: downloading,
    async downloadInvoicePdf(invoiceId) {
      logBI(10565, {
        invoiceId
      });
      try {
        setDownloading(true);
        globalContext.startLoader(loaderName);
        const pdfUrl = await api.invoice.downloadPDF(invoiceId);
        openNewTab(pdfUrl);
      } catch (e) {
        globalContext.showErrorToast('Unable to download invoice, please try again', 'download-invoice-pdf-failure');
      } finally {
        setDownloading(false);
        globalContext.stopLoader(loaderName);
      }
    }
  };
}