import uniqBy from 'lodash/uniqBy';
import { sortAlphabetically } from "../../infra/utils";
import { relationshipModel, timeModel } from "../../models";
export function getAgreementOptions(agreements, clientId) {
  if (!clientId) return [];
  return agreements.filter(a => a.customer.id === clientId && relationshipModel.isApproved(a) && !a.isSample).sort((a, b) => a.effectiveDate.getTime() - b.effectiveDate.getTime()).map(a => ({
    id: a.id,
    text: a.title || "unnamed (from ".concat(timeModel.formatDate(a.effectiveDate), ")")
  }));
}
export function getClientOptions(agreements) {
  const nonUnique = agreements.filter(a => !a.isSample).map(a => ({
    id: a.customer.id,
    text: a.customer.name
  }));
  const unique = uniqBy(nonUnique, 'text');
  return sortAlphabetically(unique, 'text', {
    ascending: true,
    ignoreCase: true
  });
}
export function getDefaultRelationshipId(agreementOptions) {
  return agreementOptions[0] ? agreementOptions[0].id : '';
}