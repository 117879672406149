import { differenceInCalendarDays, formatDistance } from 'date-fns';
import { timestampToDatestampObject, toDate } from "./translator";
export function formatDate(datestamp) {
  const date = typeof datestamp === 'number' ? toDate(timestampToDatestampObject(datestamp)) : datestamp instanceof Date ? datestamp : toDate(datestamp);
  return date.toLocaleString('en-US', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  });
}
export function formatDateWithTime(datestamp) {
  let withTimezone = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  const date = datestamp instanceof Date ? datestamp : toDate(datestamp);
  return date.toLocaleString('en-US', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: withTimezone ? 'short' : undefined
  });
}
const relativeTimeFormatter = new Intl.RelativeTimeFormat('en', {
  numeric: 'auto'
});
export function formatDaysUntil(timestamp) {
  let now = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : new Date();
  const diff = timestamp > now.getTime() ? differenceInCalendarDays(timestamp, now) : 0;
  return relativeTimeFormatter.format(diff, 'day');
}
const twoMinutes = 1000 * 60 * 2;
const twoWeeks = 1000 * 60 * 60 * 24 * 14;
export function formatTimeAgo(timestamp) {
  const now = Date.now();
  const timePassed = now - timestamp;
  if (timePassed <= twoMinutes) {
    return 'Just now';
  } else if (timePassed >= twoWeeks) {
    return formatDate(timestamp);
  } else {
    const res = formatDistance(new Date(timestamp), new Date(), {
      addSuffix: true
    });
    if (res.indexOf('about') > -1) {
      return res.replace('about ', '').replace('hour', 'hr');
    }
    if (res.indexOf('minutes') > -1) {
      return res.replace(' minutes', 'm');
    }
    return res;
  }
}