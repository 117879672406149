// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FniY8{display:flex;gap:16px;align-items:center}.tDbRP label,.Lij6Q{block-size:100%;inline-size:100%;display:flex;align-items:center}.oF0Cb{flex-shrink:0}.gmejj{margin-inline-end:4px}.ADKZv{display:flex;justify-content:space-between;width:100%;align-items:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `FniY8`,
	"uploadPdfRow": `tDbRP`,
	"loadingRow": `Lij6Q`,
	"viewTosButton": `oF0Cb`,
	"spinner": `gmejj`,
	"menuItem": `ADKZv`
};
export default ___CSS_LOADER_EXPORT___;
