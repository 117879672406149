import { InvoiceDigestStatus, InvoicePaymentDisplayStatusType } from 'anchor-scout/types';
export const invoicePaymentStatusMap = {
  [InvoiceDigestStatus.RequiresClientApproval]: InvoicePaymentDisplayStatusType.RequiresPaymentApproval,
  [InvoiceDigestStatus.PaymentsVerificationRequired]: InvoicePaymentDisplayStatusType.RequiresPaymentValidation,
  [InvoiceDigestStatus.MissingPaymentMethod]: InvoicePaymentDisplayStatusType.PaymentMethodNotSet,
  [InvoiceDigestStatus.Failed]: InvoicePaymentDisplayStatusType.PaymentFailed,
  [InvoiceDigestStatus.Disputed]: InvoicePaymentDisplayStatusType.Disputed,
  [InvoiceDigestStatus.PaymentStopped]: InvoicePaymentDisplayStatusType.StoppedPayment
};
export const invoiceTypeToTooltip = count => {
  const isMany = count > 1;
  const hasOrHave = isMany ? 'have' : 'has';
  const requireOrRequires = isMany ? 'require' : 'requires';
  const isOrAre = isMany ? 'are' : 'is';
  return {
    failed: "".concat(hasOrHave, " failed to process payment."),
    requiresClientApproval: "".concat(requireOrRequires, " client approval process payment."),
    paymentsVerificationRequired: "".concat(requireOrRequires, " payment verification process payment."),
    missingPaymentMethod: "".concat(isOrAre, " missing payment method to process payment."),
    paymentStopped: "".concat(hasOrHave, " their payment stopped."),
    disputed: "".concat(hasOrHave, " been disputed.")
  };
};