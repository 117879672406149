// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LJhHv{display:flex;flex-direction:row;flex-wrap:nowrap;justify-content:space-between;align-items:center;align-content:stretch;padding:16px;margin-top:24px;margin-bottom:24px;border-radius:4px;box-shadow:0px 2px 8px 0px rgba(40,40,99,.04)}.EPk8f{width:640px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"reportCard": `LJhHv`,
	"reportCardDetails": `EPk8f`
};
export default ___CSS_LOADER_EXPORT___;
