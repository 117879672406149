// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HgV7o{position:relative;display:flex;justify-content:center;text-align:center}.mTa5E{opacity:.4}.QQo2b{position:absolute;top:0;bottom:0;will-change:clip;transition:clip .1s}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `HgV7o`,
	"back": `mTa5E`,
	"front": `QQo2b`
};
export default ___CSS_LOADER_EXPORT___;
