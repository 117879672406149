import { defaultFeatureFlagsKey, makeDefaultLoadableContent } from "../store.utils";
import { userSelectors } from "./userSelectors";

/**
 * Returns the businessId to be used as the key to get the feature-flags.
 * Will be the vendor businessId if user is a client and we're in a relationship context.
 */
function ffBusinessId(s) {
  const {
    relationshipVendorId
  } = s.ff;
  return userSelectors.isClient(s) && relationshipVendorId || userSelectors.businessId(s);
}

/** Returns the LoadableContent of the feature-flags for the relevant businessId. */
function featureFlags(s) {
  return s.ff.featureFlags[ffBusinessId(s) || defaultFeatureFlagsKey] || makeDefaultLoadableContent();
}
function featureFlagsByBusinessId(s, businessId) {
  const key = businessId || defaultFeatureFlagsKey;
  return s.ff.featureFlags[key] || makeDefaultLoadableContent();
}
function featureFlagsDataByBusinessId(s, businessId) {
  return featureFlagsByBusinessId(s, businessId).data || {};
}
function defaultFeatureFlags(s) {
  return s.ff.featureFlags[defaultFeatureFlagsKey] || makeDefaultLoadableContent();
}

/** Returns the actual feature-flags data for the relevant businessId. */
const featureFlagsData = state => featureFlags(state).data || {};
export const ff = {
  ffBusinessId,
  featureFlags,
  featureFlagsData,
  defaultFeatureFlags,
  featureFlagsByBusinessId,
  featureFlagsDataByBusinessId
};