import { notEmpty } from "../../infra/utils";
import * as serviceModel from "../service";
function fromDTO(draft) {
  var _draft$serviceBundles;
  return Object.assign({}, draft, {
    services: draft.services.map(serviceModel.fromDTO).filter(notEmpty)
  }, draft.serviceBundles ? {
    serviceBundles: {
      bundles: (_draft$serviceBundles = draft.serviceBundles) === null || _draft$serviceBundles === void 0 ? void 0 : _draft$serviceBundles.bundles.map(bundle => Object.assign({}, bundle, {
        services: bundle.services.map(serviceModel.fromDTO).filter(notEmpty)
      }))
    }
  } : {});
}
export const translator = {
  fromDTO
};