export function prepareCreateChargeDto(dto) {
  return {
    name: dto.name,
    chargeOn: dto.chargeOn,
    serviceTemplateId: dto.serviceTemplateId || undefined,
    note: dto.note || undefined,
    rate: dto.rate,
    quantity: dto.quantity,
    temp_price: dto.temp_price || undefined,
    discount: dto.discount
  };
}