// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YVItg{flex:1;display:flex;gap:12px;align-items:center;justify-content:flex-end}._fyRi{display:flex;margin-block-end:24px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bulkActions": `YVItg`,
	"filtersRow": `_fyRi`
};
export default ___CSS_LOADER_EXPORT___;
