// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CX5vM{margin-block-end:4px}.JHYo_{display:flex;align-items:center;gap:8px}.UWrgK{width:auto;height:auto;display:inline;vertical-align:middle;margin-inline-start:4px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"serviceDetails": `CX5vM`,
	"nameContainer": `JHYo_`,
	"icon": `UWrgK`
};
export default ___CSS_LOADER_EXPORT___;
