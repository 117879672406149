import { LineItemType } from "./model";
import { LineItemPendingApprovalReason } from "../../types/api.model";
export function isAdHocCharge(lineItem) {
  return lineItem.source === LineItemType.AdHocCharge;
}
export function isAutomatic(lineItem) {
  return lineItem.source === LineItemType.Automatic;
}
export function isManual(lineItem) {
  return lineItem.source === LineItemType.Manual;
}
export function isCredit(lineItem) {
  return lineItem.source === LineItemType.Credit;
}
export function billLaterCommandId(lineItem) {
  return isManual(lineItem) ? lineItem.manual.billLaterCommandId : undefined;
}
export function serviceId(lineItem) {
  let serviceId;
  if (isAutomatic(lineItem)) serviceId = lineItem.automatic.serviceId;else if (isManual(lineItem)) {
    serviceId = lineItem.manual.serviceId;
  }
  return serviceId;
}
export function id(lineItem) {
  return lineItem.id;
}
export function quantity(lineItem) {
  let quantity;
  if (isManual(lineItem)) {
    quantity = lineItem.manual.quantity;
  } else if (isAdHocCharge(lineItem)) {
    quantity = lineItem.adHocCharge.quantity;
  }
  return quantity;
}
export function discount(lineItem) {
  let discount;
  if (isAutomatic(lineItem)) {
    discount = lineItem.automatic.discount;
  } else if (isManual(lineItem)) {
    discount = lineItem.manual.discount;
  } else if (isAdHocCharge(lineItem)) {
    discount = lineItem.adHocCharge.discount;
  }
  return discount;
}
export function pendingApprovalReason(lineItem) {
  return isManual(lineItem) ? lineItem.manual.pendingApprovalReason : isAdHocCharge(lineItem) ? lineItem.adHocCharge.pendingApprovalReason : isAutomatic(lineItem) ? lineItem.automatic.pendingApprovalReason : null;
}
export function isOverlappingPeriod(lineItem) {
  return pendingApprovalReason(lineItem) === LineItemPendingApprovalReason.OverlappingPeriod;
}