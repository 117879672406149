import { addDays } from 'date-fns';
import { InvoicePaymentMethodStatus } from "../../types/api.model";
import { InvoiceStatus } from "./model";
import { InvoicePaymentDisplayStatusType } from 'anchor-scout/types';
import { ApprovalMethodNoticePeriodType } from "../approval-method-notice-period";
export function isPending(invoice) {
  return invoice.status === InvoiceStatus.Pending;
}
export function isPaid(invoice) {
  return invoice.status === InvoiceStatus.Paid;
}
export function isConfirmed(invoice) {
  return invoice.status === InvoiceStatus.PaymentConfirmed;
}
export function isProcessing(invoice) {
  return invoice.status === InvoiceStatus.ProcessingCharge;
}
export function isPaidExternally(invoice) {
  return invoice.status === InvoiceStatus.PaidExternally;
}
export function isVoided(invoice) {
  return invoice.status === InvoiceStatus.Voided;
}
export function isRefunded(invoice) {
  return invoice.status === InvoiceStatus.Refunded;
}
export function isPaymentStopped(invoice) {
  return invoice.status === InvoiceStatus.PaymentStopped;
}
export function isPaymentFailed(invoice) {
  return invoice.status === InvoiceStatus.PaymentFailed;
}
export function isRequiresPaymentApproval(invoice) {
  return invoice.status === InvoiceStatus.RequiresPaymentApproval;
}
export function isPaymentActionRequired(invoice) {
  return invoice.status === InvoiceStatus.PaymentActionRequired;
}
export function isDisputed(invoice) {
  return invoice.status === InvoiceStatus.PaymentDisputed;
}
export function isRefundRequested(paymentStatus) {
  return !!paymentStatus && paymentStatus.type === InvoicePaymentDisplayStatusType.RefundRequested;
}
export function status(invoice) {
  return invoice.status;
}
export function paymentMethodStatus(invoice) {
  return invoice.paymentMethodStatus;
}
export function dueDate(invoice) {
  return invoice.dueDate;
}
export function formattedNumber(_ref) {
  let {
    formattedNumber
  } = _ref;
  return formattedNumber;
}
export function customerName(inv) {
  return inv.relationship.customer.name;
}
export function issuedOn(_ref2) {
  let {
    issuedOn
  } = _ref2;
  return issuedOn;
}
export function issueDate(invoice) {
  return invoice.issuedOn || invoice.createdOn;
}
export function paymentFailureMessage(_ref3) {
  let {
    paymentFailure
  } = _ref3;
  return paymentFailure === null || paymentFailure === void 0 ? void 0 : paymentFailure.message;
}
export function paymentFailureCode(_ref4) {
  let {
    paymentFailure
  } = _ref4;
  return paymentFailure === null || paymentFailure === void 0 ? void 0 : paymentFailure.code;
}
export function processingAction(_ref5) {
  let {
    processingAction
  } = _ref5;
  return {
    stripeUrl: processingAction === null || processingAction === void 0 ? void 0 : processingAction.url,
    clientSecret: processingAction === null || processingAction === void 0 ? void 0 : processingAction.clientSecret
  };
}
export function isPaymentMethodUnset(invoice) {
  return invoice.paymentMethodStatus === InvoicePaymentMethodStatus.PaymentMethodNotSet;
}
export function isPaymentMethodUnverified(invoice) {
  return invoice.paymentMethodStatus === InvoicePaymentMethodStatus.PaymentMethodUnverified;
}
export function isStableIntegrationStatus(invoice) {
  const trueFnc = () => true;
  return invoice.integration.switch({
    whenPendingSync: trueFnc,
    whenInvoiceSyncFailed: trueFnc,
    whenPaymentSyncFailed: trueFnc,
    whenRefundSyncFailed: trueFnc,
    whenVoidedRecorded: trueFnc,
    whenPaymentRecorded: trueFnc,
    whenRefundRecorded: trueFnc,
    whenInvoiceRecorded: () => isPending(invoice) || isPaidExternally(invoice) || isPaymentStopped(invoice) || isPaid(invoice) && invoice.isZeroAmount || isRequiresPaymentApproval(invoice) || isPaymentActionRequired(invoice)
  });
}
export const integration = _ref6 => {
  let {
    integration
  } = _ref6;
  return integration;
};
export function paymentStopReason(invoice) {
  return invoice.paymentStop ? invoice.paymentStop.reason : '';
}
export const autoApprovalDate = _ref7 => {
  let {
    approvalMethod,
    issuedOn
  } = _ref7;
  return approvalMethod && approvalMethod.type === ApprovalMethodNoticePeriodType.Automatic ? addDays(new Date(issuedOn), approvalMethod.auto.noticePeriodDays) : null;
};
export const totalAmount = _ref8 => {
  let {
    totalAmount
  } = _ref8;
  return totalAmount;
};
export const id = _ref9 => {
  let {
    id
  } = _ref9;
  return id;
};
export const itemName = _ref10 => {
  let {
    name
  } = _ref10;
  return name;
};
export const itemQuantity = _ref11 => {
  let {
    quantity
  } = _ref11;
  return quantity || '';
};
export const itemRate = _ref12 => {
  let {
    rate
  } = _ref12;
  return rate;
};
export const itemDiscount = _ref13 => {
  let {
    discount
  } = _ref13;
  return discount;
};
export const itemTotalAmount = _ref14 => {
  let {
    totalAmount
  } = _ref14;
  return totalAmount;
};
export const vendorName = inv => inv.relationship.vendor.name;
export const otherPartyCompanyName = (inv, isVendor) => isVendor ? customerName(inv) : vendorName(inv);
export const relationshipId = inv => inv.relationship.id;
export const relationshipTitle = inv => inv.relationship.title;
export function isSynced(inv) {
  return inv.integration.isSynced();
}
export function isNotSynced(inv) {
  return !!inv.integration && !inv.integration.isSynced();
}