// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.w8oE6{display:flex;flex-direction:row;gap:24px}.w8oE6 .OwmxL{width:512px}.w8oE6>:last-child{flex:1}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"firstRow": `w8oE6`,
	"typeInput": `OwmxL`
};
export default ___CSS_LOADER_EXPORT___;
