// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bosIn{display:flex;flex-direction:column;text-align:center;gap:12px;margin-block-start:24px;margin-block-end:12px}.t6RmP{margin-block-start:8px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"otpContainer": `bosIn`,
	"forgotYourPassword": `t6RmP`
};
export default ___CSS_LOADER_EXPORT___;
