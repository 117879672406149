function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { ignoreError } from "../../infra/utils";
export const appcues = {
  identify(userId, userEmail, userProperties) {
    ignoreError(() => {
      var _window$Appcues;
      return (_window$Appcues = window.Appcues) === null || _window$Appcues === void 0 ? void 0 : _window$Appcues.identify(userId, _objectSpread(_objectSpread({}, userProperties), {}, {
        email: userEmail
      }));
    });
  },
  sendEventDeprecated(eventName, _eventData) {
    ignoreError(() => {
      var _window$Appcues2;
      return (_window$Appcues2 = window.Appcues) === null || _window$Appcues2 === void 0 ? void 0 : _window$Appcues2.track(eventName);
    });
  },
  report(eventType, eventName) {
    ignoreError(() => {
      var _window$Appcues3;
      return (_window$Appcues3 = window.Appcues) === null || _window$Appcues3 === void 0 ? void 0 : _window$Appcues3.track(getEventNameWithProperties(eventType, eventName));
    });
  },
  sendPageView(_url, _eventData) {
    ignoreError(() => {
      var _window$Appcues4;
      return (_window$Appcues4 = window.Appcues) === null || _window$Appcues4 === void 0 ? void 0 : _window$Appcues4.page();
    });
  }
};
const getEventNameWithProperties = (eventType, eventName) => "".concat(eventType, "-").concat(eventName);