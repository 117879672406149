// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.psoV5{display:flex;gap:16px;margin-bottom:16px}.pQgti{display:flex;justify-content:space-between}.JNPfP{display:flex;align-items:center;gap:4px}.IX9ix{min-width:60px}.NZCNa>*+*{margin-block-start:24px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectors": `psoV5`,
	"discount": `pQgti`,
	"rate": `JNPfP`,
	"units": `IX9ix`,
	"callouts": `NZCNa`
};
export default ___CSS_LOADER_EXPORT___;
