import { useEffect, useRef } from 'react';
import debounce from 'lodash/debounce';
import { useHistory } from 'react-router-dom';
import { routes } from "../constants";
import { useIoContext, useStore, useStoreActions, useStoreStatic } from "../contexts";
import { useStateInRef } from 'seagull';
export const lastUserInteractionKey = 'lastUserInteraction';
const defaultUserInputDebounceTime = 500;
const uiEvents = ['click', 'keydown'];
/**
 * Logs out the user and refreshes the browser after enough time has passed since the last user interaction.
 * Syncs this behavior across all open tabs (using localStorage).
 */
export function useSessionTimeout(disableOnMount) {
  const getDisableOnMount = useStateInRef(disableOnMount);
  const timeoutRef = useRef();
  const didMount = useRef(false);
  const history = useHistory();
  const {
    locationReload,
    localStorage
  } = useIoContext();
  const {
    reset
  } = useStoreActions();
  const {
    getState
  } = useStoreStatic();
  const isLoggedIn = useStore(state => !!state.userInfo && state.authFlowDone);
  useEffect(() => {
    const {
      sessionTimeoutMS
    } = getState().session;
    const {
      inputDebounceTime
    } = getState().appConfig;
    function initSessionTimeout(updateLocalStorage) {
      if (!isLoggedIn) {
        return;
      }
      if (updateLocalStorage) {
        localStorage.setItem(lastUserInteractionKey, Date.now().toString());
      }
      timeoutRef.current && clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        if (!isLoggedIn) {
          return;
        }
        reset();
        const state = {
          sessionTimeout: true
        };
        history.push(routes.home, state);
        setTimeout(() => locationReload());
      }, sessionTimeoutMS);
    }
    function stopSessionTimeout() {
      timeoutRef.current && clearTimeout(timeoutRef.current);
    }
    const debouncedInitSessionTimeout = debounce(initSessionTimeout, inputDebounceTime != null ? inputDebounceTime : defaultUserInputDebounceTime);
    function onStorageEvent(event) {
      if (event.key === lastUserInteractionKey) {
        initSessionTimeout(false);
      }
    }
    function onUserInteraction() {
      debouncedInitSessionTimeout(true);
    }
    window.addEventListener('storage', onStorageEvent);
    uiEvents.forEach(event => window.addEventListener(event, onUserInteraction));
    if (isLoggedIn && (didMount.current || !getDisableOnMount())) {
      initSessionTimeout(true);
    }
    didMount.current = true;
    return () => {
      window.removeEventListener('storage', onStorageEvent);
      uiEvents.forEach(event => window.removeEventListener(event, onUserInteraction));
      stopSessionTimeout();
      debouncedInitSessionTimeout.cancel();
    };
  }, [isLoggedIn]);
}