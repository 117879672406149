export function getReadonlyWarning(data) {
  const {
    isMemberClient,
    isPreview,
    isSampleRelationship
  } = data;
  if (isSampleRelationship) {
    return 'It is not possible to set a payment method in test agreement';
  }
  if (isPreview) {
    return 'It is not possible to set a payment method in preview mode';
  }
  if (isMemberClient) {
    return 'Only admin users can set a payment method';
  }
  return null;
}