const sentryIgnoredErrorName = '[sentry ignore]';

/** Creates an Error object that doesn't get sent to Sentry via ErrorBoundaryPage */
export function makeSentryIgnoredError(msg) {
  const err = new Error(msg);
  err.name = sentryIgnoredErrorName;
  return err;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isSentryIgnoredError(err) {
  return err instanceof Error && err.name === sentryIgnoredErrorName;
}