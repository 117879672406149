// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jfyPl{display:flex;flex-direction:column;justify-content:space-between;min-block-size:100%;align-items:flex-start}.jfyPl>:last-child{white-space:nowrap;margin-block-end:0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actionsWrapper": `jfyPl`
};
export default ___CSS_LOADER_EXPORT___;
