export const clientContactId = _ref => {
  let {
    clientContactId
  } = _ref;
  return clientContactId;
};
export const integration = _ref2 => {
  let {
    integration
  } = _ref2;
  return integration;
};
export const items = _ref3 => {
  let {
    items
  } = _ref3;
  return items;
};
export const itemServiceId = (items, itemId) => {
  var _items$find;
  return ((_items$find = items.find(_ref4 => {
    let {
      id
    } = _ref4;
    return id === itemId;
  })) === null || _items$find === void 0 ? void 0 : _items$find.serviceId) || null;
};
export const itemAdHocChargeId = (items, itemId) => {
  var _items$find2;
  return ((_items$find2 = items.find(_ref5 => {
    let {
      id
    } = _ref5;
    return id === itemId;
  })) === null || _items$find2 === void 0 ? void 0 : _items$find2.adHocChargeId) || null;
};
export const itemServiceTemplateId = (items, itemId) => {
  var _items$find3;
  return ((_items$find3 = items.find(_ref6 => {
    let {
      id
    } = _ref6;
    return id === itemId;
  })) === null || _items$find3 === void 0 ? void 0 : _items$find3.serviceTemplateId) || null;
};