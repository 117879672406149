// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Zuh4l{margin-bottom:20px}.UYeRr{display:flex;align-items:center;justify-content:center;height:200px}.Fxsig{text-align:center;margin-top:16px;white-space:pre-wrap}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"callout": `Zuh4l`,
	"loader": `UYeRr`,
	"empty": `Fxsig`
};
export default ___CSS_LOADER_EXPORT___;
