// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.uWqJi{border-radius:4px;background:var(--bg-light, #ffffff);box-shadow:0px 4px 12px rgba(40,40,99,.06)}.TRq9O .Yhhwm{margin-bottom:16px}.TRq9O .gOh3T{display:flex;gap:12px;margin-bottom:12px;align-items:center}.TRq9O .gOh3T.VIqTl{margin-bottom:4px}.TRq9O .EfBev{margin-bottom:12px;display:flex}.TRq9O .EfBev::before{content:"";display:inline-block;width:6px;min-width:6px;height:6px;border-radius:50%;background-color:#d4d4e0;margin:8px;margin-inline-end:12px}.TOSwS{display:grid;grid-template-columns:[name] 1fr [badges] auto [price] minmax(160px, auto) [chevron] auto;gap:8px;align-items:center;padding:16px 24px;cursor:pointer}.k0jc_{display:flex;gap:8px;white-space:nowrap}.jEAXv{display:flex;align-items:center;justify-content:center;gap:4px}.hxhMW{align-self:start}.tNef7{border-top:1px solid #e4e4ec;margin:0 24px;padding-top:32px}.l3Hxl{padding:8px 8px 8px 16px;cursor:pointer;display:grid;grid-template-columns:1fr auto;gap:4px}.zLAoC{margin:8px 0}.U08b7{display:flex;flex-wrap:wrap;align-items:center;gap:4px}.U08b7>*:empty{display:none}.dFpw1{margin:8px 0;display:flex;gap:4px}.iQ_jO{margin-left:8px;padding-left:12px;border-left:1px solid #d4d4e0}.IbU3c{display:flex;flex-wrap:wrap;gap:4px;align-items:center;margin-bottom:32px}.yWPTo{flex:1 1 fit-content;flex-wrap:wrap;display:flex;gap:4px;align-items:center}.D3jWg{align-self:center}.tyTzy{margin-top:12px}.c4xo5{display:flex;align-items:center;gap:12px}.IlhKH{white-space:normal}.vU56R{color:var(--stepper-incomplete, #d4d4e0)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blue45": `#fcfcff`,
	"blue48": `#f5f7fd`,
	"blue50": `#f3f5ff`,
	"blue300": `#dae0ff`,
	"blue500": `#c0caff`,
	"blue600": `#a1aef5`,
	"blue700": `#8296ff`,
	"blue800": `#6176e7`,
	"dark50": `#fcfcfd`,
	"dark70": `#f4f4f7`,
	"dark100": `#eaeaef`,
	"dark120": `#e4e4ec`,
	"dark200": `#d4d4e0`,
	"dark300": `#bfbfd0`,
	"dark400": `#a9a9c1`,
	"dark500": `#9393b1`,
	"dark600": `#7e7ea1`,
	"dark700": `#696992`,
	"dark800": `#535382`,
	"dark900": `#282863`,
	"green45": `#eff6fa`,
	"green50": `#f3fcfa`,
	"green300": `#b6ebdf`,
	"green500": `#86ddca`,
	"green700": `#56d0b5`,
	"green800": `#36b89b`,
	"green900": `#089475`,
	"orange50": `#fdf2dc`,
	"orange300": `#fadea8`,
	"orange500": `#f9d797`,
	"orange700": `#f7ca75`,
	"orange800": `#f5bd52`,
	"orange900": `#dd9306`,
	"purple50": `#e1d9fe`,
	"purple300": `#c3b3fc`,
	"purple500": `#a58efb`,
	"purple700": `#8768f9`,
	"purple800": `#6942f8`,
	"purple900": `#4520cc`,
	"red200": `#fdf0f0`,
	"red300": `#fee3e3`,
	"red500": `#f9d5d5`,
	"red700": `#f3aaaa`,
	"red900": `#eb7272`,
	"red950": `#e86161`,
	"red970": `#dd4c4c`,
	"white": `#fff`,
	"defaultTextStandard": `#282863`,
	"defaultTextSecondary": `#696992`,
	"defaultButtonPrimary": `#6942f8`,
	"buttonPrimary": `var(--button-primary, #6942f8)`,
	"buttonPrimaryDark": `var(--button-primary-dark, #4520cc)`,
	"buttonDisabled": `var(--button-disabled, #bfbfd0)`,
	"buttonDisabledDark": `var(--button-disabled-dark, #a9a9c1)`,
	"defaultButtonDisabled": `#bfbfd0`,
	"defaultStepperCompleted": `#089475`,
	"defaultStepperIncomplete": `#d4d4e0`,
	"defaultButtonSecondary": `#fcfcfd`,
	"defaultBgStandard": `#f3f5ff`,
	"defaultBgLight": `#fff`,
	"sh10": `0px 4px 12px rgba(40,40,99,.06)`,
	"sh20": `0px 4px 16px rgba(40,40,99,.08)`,
	"sh40": `0px 4px 24px rgba(40,40,99,.12)`,
	"sh60": `0px 4px 14px rgba(40,40,99,.24)`,
	"root": `uWqJi`,
	"serviceDetails": `TRq9O`,
	"section": `Yhhwm`,
	"sectionTitle": `gOh3T`,
	"mobile": `VIqTl`,
	"listItem": `EfBev`,
	"accordionTitle": `TOSwS`,
	"badges": `k0jc_`,
	"price": `jEAXv`,
	"priceAbove": `hxhMW`,
	"accordionContent": `tNef7`,
	"mobileButton": `l3Hxl`,
	"mobileHeader": `zLAoC`,
	"mobileDetails": `U08b7`,
	"mobileBadges": `dFpw1`,
	"mobileBilling": `iQ_jO`,
	"mobileModalPrice": `IbU3c`,
	"mobileModalPriceInnerRow": `yWPTo`,
	"mobileChevron": `D3jWg`,
	"billingBullets": `tyTzy`,
	"nameWrapper": `c4xo5`,
	"name": `IlhKH`,
	"toggleIcon": `vU56R`
};
export default ___CSS_LOADER_EXPORT___;
