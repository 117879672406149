import { useRef, useEffect } from 'react';

/** Triggers the callback when one of the deps changes. Skips the 1st render (onMount) */
export const useDidUpdate = (callback, deps) => {
  const didMount = useRef(false);
  useEffect(() => {
    if (didMount.current) {
      return callback();
    } else {
      didMount.current = true;
    }
  }, deps); // eslint-disable-line
};