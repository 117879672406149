export function id(qboCustomer) {
  return qboCustomer.id;
}
export function customerName(qboCustomer) {
  return qboCustomer.customerName;
}
export function contactName(qboCustomer) {
  return qboCustomer.contactName;
}
export function displayName(qboCustomer) {
  return qboCustomer.displayName;
}
export function emailAddress(qboCustomer) {
  return qboCustomer.emailAddress;
}
export function status(qboCustomer) {
  return qboCustomer.status;
}
export function modifiedDate(qboCustomer) {
  return qboCustomer.modifiedDate;
}
export function sourceModifiedDate(qboCustomer) {
  return qboCustomer.sourceModifiedDate;
}