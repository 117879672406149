// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vk3Q6{display:flex;align-items:center;justify-content:space-between}.vk3Q6 :first-child{flex:1}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"serviceName": `vk3Q6`
};
export default ___CSS_LOADER_EXPORT___;
