// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rpv4A{display:flex;width:100%;margin-bottom:36px;justify-content:space-around}.a_Va2{margin-top:16px;margin-bottom:24px;width:100%}.semHe{text-align:start}.tYL5V{display:flex;align-items:flex-start;flex-direction:column}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fieldsContainer": `rpv4A`,
	"inputField": `a_Va2`,
	"infoParagraph": `semHe`,
	"reasonContainer": `tYL5V`
};
export default ___CSS_LOADER_EXPORT___;
