// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.njVNf{display:flex;flex-direction:column;gap:32px}.NXt6I{white-space:pre-wrap}._ff2W{display:flex;flex-direction:column;gap:24px;padding-bottom:16px}.bctgd{display:flex;align-items:center;justify-content:center;height:366px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContentWrapper": `njVNf`,
	"customerName": `NXt6I`,
	"notificationEmailFields": `_ff2W`,
	"loading": `bctgd`
};
export default ___CSS_LOADER_EXPORT___;
