// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.D0mqg{display:flex;gap:12px;margin-bottom:12px;align-items:center}.enW0H{display:flex;flex-direction:column;width:min-content}.GWPP5{margin-bottom:12px;align-self:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sectionTitle": `D0mqg`,
	"onlyPrimaryCanSelectCallout": `enW0H`,
	"onlyPrimaryCanSelectCalloutContent": `GWPP5`
};
export default ___CSS_LOADER_EXPORT___;
