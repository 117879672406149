// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EnnQL{width:100%;height:100%;display:flex;flex-direction:column;gap:8px;align-items:center;justify-content:center}.yiFkO{width:100%;height:100%;display:flex;flex-direction:column}.yiFkO .KA2v5{flex:1;display:flex;flex-direction:column;gap:4px;justify-content:center;text-align:center}.yiFkO .TBUqU{align-self:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"savingContainer": `EnnQL`,
	"errorContainer": `yiFkO`,
	"content": `KA2v5`,
	"footer": `TBUqU`
};
export default ___CSS_LOADER_EXPORT___;
