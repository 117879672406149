import { ServerError } from "../infra/ErrorResponse";
export function getSaveContactErrorMessage(e) {
  if (e.hasPhoneValidationError()) {
    return 'The contact details were not saved because the phone number is invalid';
  } else if (e.hasServerError(ServerError.DuplicateContact)) {
    return 'A contact with the same email address and company name already exists';
  } else if (e.hasServerError(ServerError.ContactVersionMismatch)) {
    return 'This contact was already modified by someone else. Please refresh the page and try again.';
  } else if (e.hasServerError(ServerError.ContactNotEditable)) {
    return 'This contact is not editable';
  } else if (e.hasServerError(ServerError.UserWithEmailAlreadyExists)) {
    return 'A contact with the same email address already exists';
  } else {
    return 'Failed to save contact';
  }
}