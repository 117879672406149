import colors from 'seagull/foundations/colors.module.scss';
export const chartColors = {
  lightBlue: colors.blue600,
  blue: colors.blue800,
  green: colors.green700,
  lightGray: colors.dark200,
  red: colors.red950,
  lightRed: colors.red700,
  axisTicks: colors.dark700,
  labels: colors.dark700,
  axisLine: colors.dark200,
  grid: colors.dark70
};