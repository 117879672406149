import { MoneyModel } from "../MoneyModel";
import { InvoiceDigestStatus } from "../../../../scout/types";
export class InvoicesDigestModel {
  static fromDTO(dto) {
    return new InvoicesDigestModel({
      statuses: {
        paymentsVerificationRequired: InvoicesDigestModel.invoiceStatusGroupFromDTO(dto.statuses.paymentsVerificationRequired),
        requiresClientApproval: InvoicesDigestModel.invoiceStatusGroupFromDTO(dto.statuses.requiresClientApproval),
        paymentStopped: InvoicesDigestModel.invoiceStatusGroupFromDTO(dto.statuses.paymentStopped),
        disputed: InvoicesDigestModel.invoiceStatusGroupFromDTO(dto.statuses.disputed),
        failed: InvoicesDigestModel.invoiceStatusGroupFromDTO(dto.statuses.failed),
        missingPaymentMethod: InvoicesDigestModel.invoiceStatusGroupFromDTO(dto.statuses.missingPaymentMethod)
      },
      processing: InvoicesDigestModel.invoiceStatusGroupFromDTO(dto.processing),
      requiresAttention: InvoicesDigestModel.invoiceStatusGroupFromDTO(dto.requiresAttention),
      hasCreatedInvoice: dto.hasCreatedInvoice
    });
  }
  static invoiceStatusGroupFromDTO(dto) {
    return {
      count: dto.count,
      amount: MoneyModel.fromDTO(dto.amount)
    };
  }
  constructor(invoicesDigest) {
    this.invoicesDigest = invoicesDigest;
  }
  invoicesPercentages() {
    const totalAmount = this.totalAmount();
    if (totalAmount === 0) {
      return [];
    }
    return Object.entries(this.invoicesToDisplay()).map(_ref => {
      let [invoiceType, value] = _ref;
      return {
        type: invoiceType,
        count: value.count,
        amount: value.amount,
        percentage: value.amount.amount().div(totalAmount).mul(100).toNumber()
      };
    });
  }
  invoicesTypeAmount() {
    return this.invoiceTypesToDisplay().length;
  }
  invoiceTypesToDisplay() {
    const statusesOrder = {
      [InvoiceDigestStatus.RequiresClientApproval]: 0,
      [InvoiceDigestStatus.PaymentsVerificationRequired]: 1,
      [InvoiceDigestStatus.MissingPaymentMethod]: 2,
      [InvoiceDigestStatus.Failed]: 3,
      [InvoiceDigestStatus.Disputed]: 4,
      [InvoiceDigestStatus.PaymentStopped]: 5
    };
    return Object.entries(statusesOrder).sort((a, b) => a[1] - b[1]).map(_ref2 => {
      let [status] = _ref2;
      return status;
    }).filter(status => this.invoicesDigest.statuses[status].count > 0);
  }
  invoicesToDisplay() {
    const invoicesToDisplay = {};
    this.invoiceTypesToDisplay().forEach(invoicesTypesToDisplay => {
      invoicesToDisplay[invoicesTypesToDisplay] = this.invoicesDigest.statuses[invoicesTypesToDisplay];
    });
    return invoicesToDisplay;
  }
  invoiceTypeToInvoicesGroup(invoiceType) {
    return this.invoicesDigest.statuses[invoiceType];
  }
  hasCreatedInvoice() {
    return this.invoicesDigest.hasCreatedInvoice;
  }
  processing() {
    return this.invoicesDigest.processing;
  }
  requiresAttention() {
    return this.invoicesDigest.requiresAttention;
  }
  totalAmount() {
    return this.invoicesDigest.requiresAttention.amount.toNumber();
  }
}