// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gcjJY{height:100%;width:100%;display:grid;justify-content:center;align-content:center}.b2pG2{background:linear-gradient(297.2deg, #F0EFFA 16.5%, #FCFCFF 100%);width:100vw;height:100vh}.rXSnX{max-width:60%;min-width:200px;margin:0 auto 24px}.Xk2Es{font-family:"Lufga-Regular";font-size:1.5em;line-height:2em;color:#282864;text-align:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `gcjJY`,
	"fullPage": `b2pG2`,
	"logo": `rXSnX`,
	"text": `Xk2Es`
};
export default ___CSS_LOADER_EXPORT___;
