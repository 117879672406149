// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zwcqc{box-sizing:border-box;padding:24px;position:relative;border-radius:4px;display:flex;flex-direction:column;max-height:530px}.WeH1i{position:absolute;right:8px;top:8px}._vweY{display:flex;flex-direction:column;gap:20px;flex:1;overflow-y:auto;margin:8px 0;padding:16px 0}.mrh95{width:100%;margin-top:4px}.rNTYs{margin-bottom:8px;display:flex;gap:8px}.FjkXZ{padding-top:8px;padding-bottom:8px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"popover": `zwcqc`,
	"closeButtonContainer": `WeH1i`,
	"checkboxes": `_vweY`,
	"searchbox": `mrh95`,
	"filtersRow": `rNTYs`,
	"row": `FjkXZ`
};
export default ___CSS_LOADER_EXPORT___;
