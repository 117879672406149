// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.q4CRB{display:flex;align-items:center;justify-content:space-between;inline-size:100%}.q4CRB:not(:empty){padding-block:12px}.qvM3b{margin-inline-start:auto}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `q4CRB`,
	"actions": `qvM3b`
};
export default ___CSS_LOADER_EXPORT___;
