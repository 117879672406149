import { getRequiredValidator } from "./required";
import { getNoHtmlPatternError, noHtmlPatternValidator } from "./rawValidators";

/** true means there's no error. false means there's an error. string means there's an error and gives the error-message. */
export function getRichTextValidator(valueName) {
  let required = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  const requiredValidator = getRequiredValidator(required);
  return value => {
    const requiredError = requiredValidator(value);
    if (requiredError !== true) {
      return requiredError;
    }
    if (!noHtmlPatternValidator(value)) {
      return getNoHtmlPatternError(valueName);
    }
    return true;
  };
}

/** For react-hook-form library. */
export const richTextValidation = function richTextValidation(valueName) {
  let {
    required
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return {
    validate: {
      richText: getRichTextValidator(valueName, required)
    },
    dataAttr: {
      'data-validation': 'rich-text'
    }
  };
};