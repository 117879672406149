import Decimal from 'decimal.js';
export class AvailableCreditModel {
  static sumCredits(credits) {
    return credits.filter(c => !c.isPaused()).reduce((a, b) => a.plus(b.totalAmount()), new Decimal(0)).abs();
  }
  constructor(dto) {
    this.dto = dto;
    this.totalAmountDecimal = new Decimal(dto.totalAmount).div(100).toDP(2);
  }
  toJSON() {
    return this.dto;
  }
  id() {
    return this.dto.id;
  }
  name() {
    return this.dto.name;
  }
  isPaused() {
    return this.dto.isPaused;
  }
  totalAmount() {
    return this.totalAmountDecimal;
  }
}