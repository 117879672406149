import { useEffect } from 'react';
import { useStateInRef } from 'seagull';
import { usePusherBusinessChannel } from "./PusherBusinessChannelProvider";
/** Responsible for binding to an event in the pusher business channel. */
export function BusinessChannelEventBinder(props) {
  const {
    eventName
  } = props;
  const getProps = useStateInRef(props);
  const {
    getChannel,
    channelBusinessId
  } = usePusherBusinessChannel();
  useEffect(() => {
    var _getChannel, _getProps$onBind, _getProps;
    if (!channelBusinessId) return;
    (_getChannel = getChannel()) === null || _getChannel === void 0 || _getChannel.bind(eventName, event => {
      return getProps().callback(event);
    });
    (_getProps$onBind = (_getProps = getProps()).onBind) === null || _getProps$onBind === void 0 || _getProps$onBind.call(_getProps);
    return () => {
      var _getChannel2;
      (_getChannel2 = getChannel()) === null || _getChannel2 === void 0 || _getChannel2.unbind(eventName);
    };
  }, [channelBusinessId, eventName]);
  return null;
}