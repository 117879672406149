// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BJelw{flex:1;display:flex;flex-direction:row;justify-content:center;align-items:center;gap:20px;height:100%}.ebcj6{display:flex;flex-direction:row;gap:16px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `BJelw`,
	"bottom": `ebcj6`
};
export default ___CSS_LOADER_EXPORT___;
