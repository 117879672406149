import { useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import * as Sentry from '@sentry/react';
import { useStoreActions } from "../contexts";

/** Loads stripe, and sets a boolean flag in the store if loading fails. */
export const useLoadStripe = () => {
  const [stripeObj, setStripeObj] = useState(null);
  const {
    setStripeLoadingFailed
  } = useStoreActions();
  useEffect(() => {
    loadStripe(window.stripePublicToken).then(setStripeObj).catch(err => {
      setStripeLoadingFailed(true);
      Sentry.captureException(err);
    });
  }, []);
  return stripeObj;
};