// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.N2eVF{display:flex;flex-direction:column;gap:16px}.N2eVF.eNR7z{justify-content:center}.ubyZf{align-self:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `N2eVF`,
	"centerVertically": `eNR7z`,
	"addServiceBtn": `ubyZf`
};
export default ___CSS_LOADER_EXPORT___;
