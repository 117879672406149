import { useState } from 'react';
import { useGlobalContext, useIsMounted } from 'seagull';
import { handlePromise, wait } from "../../infra/utils";
import { useStore, useStoreActions } from "../../contexts";
import { integratedInvoiceModel, invoiceModel } from "../../models";
export function useSyncInvoice(invoice, reloadInvoice) {
  const [isSyncing, setIsSyncing] = useState(false);
  const qboPollingInterval = useStore(state => state.appConfig.qboPollingInterval);
  const {
    api
  } = useStoreActions();
  const isMounted = useIsMounted();
  const globalContext = useGlobalContext();
  async function syncInvoice() {
    let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    const {
      isRetry
    } = options;
    setIsSyncing(true);
    const {
      success
    } = await handlePromise(isRetry ? api.qbo.retrySyncInvoice(invoice.id) : api.qbo.syncInvoice(invoice.id));
    if (!isMounted()) return;
    if (!success) {
      openSyncToast('failure');
      setIsSyncing(false);
      return;
    }
    await wait(qboPollingInterval);
    const newIntegrationData = await api.invoice.integrationDetails(invoiceModel.id(invoice), invoice.isZeroAmount);
    if (!isMounted()) return;
    const integration = integratedInvoiceModel.integration(newIntegrationData);
    setIsSyncing(false);
    openSyncToast(integration.isSyncFailed() ? 'failure' : 'success');
    reloadInvoice();
  }
  function openSyncToast(type) {
    if (type === 'success') {
      globalContext.showSuccessToast("Invoice ".concat(invoice.formattedNumber, " was successfully synced"), 'sync-success-toast');
    } else {
      globalContext.showErrorToast("Failed to sync invoice ".concat(invoice.formattedNumber), 'sync-failure-toast');
    }
  }
  return {
    isSyncing,
    syncInvoice
  };
}