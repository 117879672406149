import { useIoContext, useStoreActions } from "../contexts";
import { useStateInRef } from 'seagull';
import { InquiryStatusEnum } from 'anchor-kyb-service/types';
export function usePersona(props) {
  const getProps = useStateInRef(props);
  const {
    personaClient
  } = useIoContext();
  const {
    api
  } = useStoreActions();
  return {
    async open(businessId) {
      const {
        onError,
        onReady,
        onComplete
      } = getProps();
      const client = personaClient(getProps());
      if (businessId != null) {
        try {
          const {
            inquiryId,
            sessionToken,
            inquiryStatus
          } = await api.vendor.getKYBInquiry(businessId);
          if (InquiryStatusEnum.NeedsReview === inquiryStatus) {
            onReady && onReady();
            if (onComplete != null) onComplete({
              inquiryId,
              status: inquiryStatus,
              fields: {}
            });
            return;
          }
          client.open(inquiryId, sessionToken);
        } catch (exn) {
          onError && onError(exn);
        }
      }
    }
  };
}