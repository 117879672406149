const _excluded = ["previousState"];
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var n = Object.getOwnPropertySymbols(e); for (r = 0; r < n.length; r++) o = n[r], t.indexOf(o) >= 0 || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.indexOf(n) >= 0) continue; t[n] = r[n]; } return t; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
export function getInitialSelection() {
  return {
    selectAll: true,
    selectedOptionsIndexes: new Set(),
    labelToDisplay: null,
    //not in use, calculated in the render function
    previousState: {
      selectAll: true,
      selectedOptionsIndexes: new Set(),
      labelToDisplay: null
    }
  };
}
function shouldMarkSelectAll(selectOptionsIndexes) {
  return selectOptionsIndexes.size === 0;
}
function reducer(state, action) {
  switch (action.type) {
    case 'selectAll':
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          selectAll: true,
          selectedOptionsIndexes: new Set()
        });
      }
    case 'selectOption':
      {
        const isInMap = state.selectedOptionsIndexes.has(action.value);
        const newSelectedOptionsIndexes = new Set(state.selectedOptionsIndexes);
        if (isInMap) {
          newSelectedOptionsIndexes.delete(action.value);
        } else {
          newSelectedOptionsIndexes.add(action.value);
        }
        return _objectSpread(_objectSpread({}, state), {}, {
          selectAll: shouldMarkSelectAll(newSelectedOptionsIndexes),
          selectedOptionsIndexes: newSelectedOptionsIndexes
        });
      }
    case 'selectOptions':
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          selectAll: action.value.length === 0,
          selectedOptionsIndexes: new Set(action.value)
        });
      }
    case 'applyChanges':
      {
        const {
            previousState: _
          } = state,
          currentState = _objectWithoutProperties(state, _excluded);
        const newState = _objectSpread(_objectSpread({}, currentState), {}, {
          labelToDisplay: action.label || null
        });
        return _objectSpread(_objectSpread({}, newState), {}, {
          previousState: _objectSpread({}, newState)
        });
      }
    case 'revert':
      {
        const {
          previousState
        } = state;
        return _objectSpread(_objectSpread({}, previousState), {}, {
          previousState
        });
      }
    default:
      throw new Error('unknown filters action');
  }
}
export function createReducer() {
  return reducer;
}