// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.r6xiE{display:flex;flex-direction:column}.VSqNA{font-family:"Lufga-Medium";font-weight:400;font-size:var(--font-size-h6, 20px);line-height:var(--line-height-h6, 35px);letter-spacing:.01em;color:#282863;align-self:center}@media(max-width: 560px){.VSqNA{font-family:"Lufga-Regular";font-weight:400;font-size:var(--font-size-p1, 16px);line-height:var(--line-height-p1, 24px);letter-spacing:.015em}}.b3nOR{font-family:"Lufga-Regular";font-weight:400;font-size:var(--font-size-p2, 14px);line-height:var(--line-height-p2, 18px);letter-spacing:.02em;color:#696992;margin-block-start:4px;align-self:center}@media(max-width: 560px){.b3nOR{font-family:"Lufga-Regular";font-weight:400;font-size:var(--font-size-p3, 12px);line-height:var(--line-height-p3, 16px);letter-spacing:.02em}}.CR8cv{font-family:"Lufga-Regular";font-weight:400;font-size:var(--font-size-p1, 16px);line-height:var(--line-height-p1, 24px);letter-spacing:.015em;color:var(--text-secondary, #696992)}@media(max-width: 560px){.CR8cv{margin-block-start:8px}}.cmDF1{margin-block-end:32px;margin-block-start:32px}@media(max-width: 560px){.cmDF1{margin-block-start:24px}}.bohfJ{display:flex;flex-direction:column;justify-content:center;gap:32px}.moE4l{display:flex;flex-direction:row;align-items:stretch;gap:24px}@media(max-width: 560px){.moE4l{flex-direction:column;gap:32px}}.k3mV3{display:flex;flex-direction:column}.vlQv3{margin-block-start:16px;text-align:center}.D6KnE{margin-block-start:8px;text-align:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blue45": `#fcfcff`,
	"blue48": `#f5f7fd`,
	"blue50": `#f3f5ff`,
	"blue300": `#dae0ff`,
	"blue500": `#c0caff`,
	"blue600": `#a1aef5`,
	"blue700": `#8296ff`,
	"blue800": `#6176e7`,
	"dark50": `#fcfcfd`,
	"dark70": `#f4f4f7`,
	"dark100": `#eaeaef`,
	"dark120": `#e4e4ec`,
	"dark200": `#d4d4e0`,
	"dark300": `#bfbfd0`,
	"dark400": `#a9a9c1`,
	"dark500": `#9393b1`,
	"dark600": `#7e7ea1`,
	"dark700": `#696992`,
	"dark800": `#535382`,
	"dark900": `#282863`,
	"green45": `#eff6fa`,
	"green50": `#f3fcfa`,
	"green300": `#b6ebdf`,
	"green500": `#86ddca`,
	"green700": `#56d0b5`,
	"green800": `#36b89b`,
	"green900": `#089475`,
	"orange50": `#fdf2dc`,
	"orange300": `#fadea8`,
	"orange500": `#f9d797`,
	"orange700": `#f7ca75`,
	"orange800": `#f5bd52`,
	"orange900": `#dd9306`,
	"purple50": `#e1d9fe`,
	"purple300": `#c3b3fc`,
	"purple500": `#a58efb`,
	"purple700": `#8768f9`,
	"purple800": `#6942f8`,
	"purple900": `#4520cc`,
	"red200": `#fdf0f0`,
	"red300": `#fee3e3`,
	"red500": `#f9d5d5`,
	"red700": `#f3aaaa`,
	"red900": `#eb7272`,
	"red950": `#e86161`,
	"red970": `#dd4c4c`,
	"white": `#fff`,
	"defaultTextStandard": `#282863`,
	"defaultTextSecondary": `#696992`,
	"defaultButtonPrimary": `#6942f8`,
	"buttonPrimary": `var(--button-primary, #6942f8)`,
	"buttonPrimaryDark": `var(--button-primary-dark, #4520cc)`,
	"buttonDisabled": `var(--button-disabled, #bfbfd0)`,
	"buttonDisabledDark": `var(--button-disabled-dark, #a9a9c1)`,
	"defaultButtonDisabled": `#bfbfd0`,
	"defaultStepperCompleted": `#089475`,
	"defaultStepperIncomplete": `#d4d4e0`,
	"defaultButtonSecondary": `#fcfcfd`,
	"defaultBgStandard": `#f3f5ff`,
	"defaultBgLight": `#fff`,
	"fontFamilyLight": `"Lufga-Regular"`,
	"fontFamilyNormal": `"Lufga-Medium"`,
	"fontFamilyBold": `"Lufga-SemiBold"`,
	"fontSizeH1": `var(--font-size-h1, 48px)`,
	"fontSizeH2": `var(--font-size-h2, 40px)`,
	"fontSizeH3": `var(--font-size-h3, 33px)`,
	"fontSizeH4": `var(--font-size-h4, 28px)`,
	"fontSizeH5": `var(--font-size-h5, 22px)`,
	"fontSizeH6": `var(--font-size-h6, 20px)`,
	"fontSizeP1": `var(--font-size-p1, 16px)`,
	"fontSizeP2": `var(--font-size-p2, 14px)`,
	"fontSizeP3": `var(--font-size-p3, 12px)`,
	"lineHeightH1": `var(--line-height-h1, 64px)`,
	"lineHeightH2": `var(--line-height-h2, 56px)`,
	"lineHeightH3": `var(--line-height-h3, 40px)`,
	"lineHeightH4": `var(--line-height-h4, 40px)`,
	"lineHeightH5": `var(--line-height-h5, 32px)`,
	"lineHeightH6": `var(--line-height-h6, 35px)`,
	"lineHeightP1": `var(--line-height-p1, 24px)`,
	"lineHeightP2": `var(--line-height-p2, 18px)`,
	"lineHeightP3": `var(--line-height-p3, 16px)`,
	"mobileMaxWidth": `560px`,
	"tabletMinWidth": `561px`,
	"tabletMaxWidth": `1024px`,
	"desktopMinWidth": `1025px`,
	"smallScreenMaxWidth": `1400px`,
	"largeScreenMinWidth": `1401px`,
	"formContainer": `r6xiE`,
	"title": `VSqNA`,
	"subTitle": `b3nOR`,
	"description": `CR8cv`,
	"formFields": `cmDF1`,
	"verticalFormFields": `bohfJ`,
	"horizontalFormFields": `moE4l`,
	"submitButtonField": `k3mV3`,
	"footer": `vlQv3`,
	"error": `D6KnE`
};
export default ___CSS_LOADER_EXPORT___;
