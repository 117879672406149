import { VideoSource, getVideoSource } from "../../../VideoPlayer/VideoPlayer.utils";
const youtubeTitle = internalPlayer => {
  var _internalPlayer$getVi;
  return (_internalPlayer$getVi = internalPlayer.getVideoData) === null || _internalPlayer$getVi === void 0 ? void 0 : _internalPlayer$getVi.call(internalPlayer).title;
};
const vimeoTitle = internalPlayer => {
  var _internalPlayer$eleme;
  return (_internalPlayer$eleme = internalPlayer.element) === null || _internalPlayer$eleme === void 0 ? void 0 : _internalPlayer$eleme.title;
};
export const extractReactPlayerVideoName = (videoLink, reactPlayer) => {
  const source = getVideoSource(new URL(videoLink));
  const internalPlayer = reactPlayer.getInternalPlayer();
  if (source === VideoSource.YOUTUBE) {
    return youtubeTitle(internalPlayer);
  }
  if (source === VideoSource.VIMEO) {
    return vimeoTitle(internalPlayer);
  }
  throw new Error('Unsupported video source');
};
export const isUrlValid = videoLink => {
  try {
    const parsedVideoLink = new URL(videoLink);
    return getVideoSource(parsedVideoLink) !== VideoSource.UNSUPPORTED;
  } catch (err) {
    return false;
  }
};