// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JYQKB{display:flex;height:16px;width:100%;border-radius:8px;overflow:hidden}.PMsKa{min-width:24px}.PMsKa:hover{filter:brightness(1.1)}.v0Ank{height:32px}.bk1sG{height:40px}.kxpyQ{display:flex;align-items:center;gap:8px}.VmP34{width:16px;height:16px;border-radius:50%}.zNKSo{padding:16px;display:flex;flex-direction:column;gap:16px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"invoiceBarContent": `JYQKB`,
	"invoiceBarPercentage": `PMsKa`,
	"sixItemsInvoiceInfoRow": `v0Ank`,
	"lessThanSixItemsInvoiceInfoRow": `bk1sG`,
	"dotContainer": `kxpyQ`,
	"dot": `VmP34`,
	"bottomCardContent": `zNKSo`
};
export default ___CSS_LOADER_EXPORT___;
