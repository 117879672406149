import compact from 'lodash/compact';
import { serviceModel } from "../../models";
import { BillingTriggerType } from "../../types/api.model";
import { pluralizeUnitName } from "../../utils/unit-name.utils";
export function getServiceDescriptionBadges(service, isActiveAgreement) {
  const {
    billing,
    cost,
    participation
  } = service;
  const {
    discount
  } = cost;
  return compact([(() => {
    switch (billing.trigger) {
      case BillingTriggerType.OnApproval:
        return isActiveAgreement ? {
          text: 'Billed once, automatically',
          intent: 'type-approval-amendment'
        } : {
          text: 'Billed once, on agreement approval',
          intent: 'type-approval'
        };
      case BillingTriggerType.OneTimeDelivery:
        return {
          text: 'Billed once, on approval of delivery',
          intent: 'type-delivery'
        };
      case BillingTriggerType.OneTimeManual:
        return {
          text: 'Billed once, manually',
          intent: 'type-onetime-manual'
        };
      case BillingTriggerType.RepeatableManual:
        return {
          text: "Billed ".concat(billing.recurrencePeriod, ", manually"),
          intent: 'type-repeatable-manual'
        };
      case BillingTriggerType.Ongoing:
      default:
        return {
          text: "Billed ".concat(billing.recurrencePeriod, ", automatically"),
          intent: 'type-ongoing'
        };
    }
  })(), discount && discount.amount().gt(0) && {
    skin: 'success',
    intent: "".concat(discount.type(), "-discount"),
    text: "".concat(discount.format(), " Off")
  }, cost.pricingType === 'variable' && cost.unitCap && cost.unitCap.gt(0) && {
    skin: 'success',
    intent: 'preapproved',
    text: "".concat(cost.unitCap.toString(), " ").concat(pluralizeUnitName(cost.unitCap, cost.unitName), " preapproved")
  }, 'isProrated' in billing && billing.isProrated && {
    skin: 'warning',
    intent: 'prorated',
    text: 'Prorated',
    tooltip: 'This service is prorated'
  }, 'isProrated' in billing && billing.isPaused && {
    skin: 'warning',
    intent: 'paused',
    text: 'Paused',
    tooltip: 'Automatic billing is paused for this service'
  }, participation.type === 'optional' && {
    skin: 'warning',
    intent: 'optional',
    text: 'Optional',
    tooltip: 'This service is marked as optional'
  }, serviceModel.maxChargesEnabled(service) && serviceModel.validateMaxCharges(service).length === 0 && {
    skin: 'warning',
    intent: 'limit',
    text: "Can be billed ".concat(serviceModel.maxChargesLimit(service) === 1 ? 'once' : serviceModel.maxChargesLimit(service) + ' times'),
    tooltip: 'This service has a limited number of charges'
  }, serviceModel.isOngoing(serviceModel.billingTrigger(service)) && serviceModel.isBilledUpfront(service) && !serviceModel.isSkipBillingOnAcceptance(service) && {
    skin: 'warning',
    intent: isActiveAgreement ? 'bill-immediately' : 'bill-on-approval',
    text: "Charged ".concat(isActiveAgreement ? 'immediately' : 'on acceptance'),
    tooltip: "This service will be billed ".concat(isActiveAgreement ? 'immediately' : 'on agreement approval')
  }, !!serviceModel.priceIncrease(service) && {
    skin: 'warning',
    intent: 'price-increase',
    text: 'Auto-price increase'
  }]);
}