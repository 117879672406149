// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.R3_ef{display:flex;flex-direction:column;align-items:stretch;justify-content:space-between;gap:24px;min-block-size:512px}.nnp3X{display:flex;flex-direction:column;align-items:center;gap:4px;text-align:center;padding:0 12px}.aAMjO{flex:1;display:flex;flex-direction:column;align-items:stretch;justify-content:space-between;padding:0 24px}.aAMjO .PltvP{align-self:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `R3_ef`,
	"header": `nnp3X`,
	"content": `aAMjO`,
	"footer": `PltvP`
};
export default ___CSS_LOADER_EXPORT___;
