// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OVL3I{display:grid;gap:32px 24px;grid-template-columns:1fr 1fr}.LxHPH{grid-column:span 2}.YxsPG{display:flex;flex-direction:column;align-items:center;justify-content:center;text-align:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"widgets": `OVL3I`,
	"fullWidth": `LxHPH`,
	"emptyStateWithAction": `YxsPG`
};
export default ___CSS_LOADER_EXPORT___;
