// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hIa_S{margin-top:12px}.dYbh8{min-height:60px;min-width:fit-content}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `hIa_S`,
	"row": `dYbh8`
};
export default ___CSS_LOADER_EXPORT___;
