function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { legalTermsModel, serviceModel } from "../../models";
import { RelationshipProgressStatus, RelationshipStatus } from "../../models/relationship";
import { notEmpty } from "../../infra/utils";
import { businessSettingsDefaults } from "../../constants";
import { RecurrencePeriodTypes } from "../../models/service/model";
import { makeBillingOnApproval } from "../../models/service-template/makers";
import { makeDefaultBranding } from "../../store/store.utils";
import { makeIntroVideoNone } from "../../models/intro-video/makers";
import { makeFixedCost } from "../../models/service-cost";
const defaultCreatedBy = {
  id: '',
  email: '',
  firstName: '',
  lastName: '',
  phone: null
};
export function getRelationshipFromDraft(data) {
  var _sampleContact$custom, _contactsMap$draft$cl;
  const {
    draft,
    sampleContact,
    contactsMap,
    legalTerms,
    userInfo = {},
    worksAt,
    generateUniqueId
  } = data;
  return _objectSpread(_objectSpread({}, draft), {}, {
    buyer: draft.isSample ? sampleContact.toAssignedUser() : draft.clientContactId && contactsMap[draft.clientContactId] ? contactsMap[draft.clientContactId].toAssignedUser() : getDefaultBuyer(),
    vendor: getVendor(worksAt),
    seller: getSeller(userInfo),
    customer: draft.isSample ? {
      id: (_sampleContact$custom = sampleContact.customerId()) !== null && _sampleContact$custom !== void 0 ? _sampleContact$custom : '',
      name: sampleContact.companyName()
    } : draft.clientContactId && contactsMap[draft.clientContactId] != null ? {
      id: (_contactsMap$draft$cl = contactsMap[draft.clientContactId].customerId()) !== null && _contactsMap$draft$cl !== void 0 ? _contactsMap$draft$cl : '',
      name: contactsMap[draft.clientContactId].companyName()
    } : getDefaultCustomer(),
    legalTerms: legalTerms.find(terms => {
      var _draft$legalTerms, _draft$legalTerms2;
      return ((_draft$legalTerms = draft.legalTerms) === null || _draft$legalTerms === void 0 ? void 0 : _draft$legalTerms.type) === 'libraryLegalTermsId' && terms.id === ((_draft$legalTerms2 = draft.legalTerms) === null || _draft$legalTerms2 === void 0 ? void 0 : _draft$legalTerms2.libraryLegalTermsId);
    }) || legalTermsModel.fromDraftLegalTerms(draft.legalTerms),
    services: draft.services.map(serviceModel.fromDTO).filter(notEmpty),
    serviceBundles: draft.serviceBundles ? {
      bundles: draft.serviceBundles.bundles.map(b => _objectSpread(_objectSpread({}, b), {}, {
        services: b.services.map(serviceModel.fromDTO).filter(notEmpty)
      }))
    } : undefined,
    amendmentsApprovalMethod: draft.amendmentsApprovalMethod || businessSettingsDefaults.amendmentsApprovalMethod,
    invoicesApprovalMethod: draft.invoicesApprovalMethod || businessSettingsDefaults.invoicesApprovalMethod,
    status: RelationshipStatus.Pending,
    createdOn: 0,
    automaticPaymentsEnabled: draft.requirePaymentMethod && !draft.allowManualPayments,
    version: '',
    signedOn: null,
    terminatedOn: null,
    paymentMethod: null,
    signature: null,
    approvalFlow: draft.isExistingCustomer ? 'invite' : 'proposal',
    suggestedCustomerName: null,
    invoiceStatus: {
      numPaymentActionRequired: 0,
      numRequiresPaymentApproval: 0
    },
    serviceStatus: {
      numAmended: 0
    },
    progressStatus: RelationshipProgressStatus.Sent,
    progressStatusTimestamp: 0,
    adHocChargeStatus: {
      numAwaitingApproval: 0
    },
    notificationStatus: [],
    createdBy: defaultCreatedBy,
    changes: null,
    statusTimestamp: 0,
    approvalRecord: {
      ip: '',
      signature: '',
      timestamp: 0
    },
    reviewers: draft.reviewers.filter(r => !r.isSignatory).map(r => ({
      id: generateUniqueId('user'),
      email: r.email || '',
      firstName: r.firstName || null,
      lastName: r.lastName || null
    })),
    signatories: draft.reviewers.filter(r => r.isSignatory).map(r => ({
      id: generateUniqueId('user'),
      email: r.email || '',
      firstName: r.firstName || null,
      lastName: r.lastName || null,
      approvalRecord: null
    })),
    additionalApprovalRecords: null,
    customerSelections: null
  });
}
export function getSetupBrandRelationship(data) {
  var _worksAt$settings, _worksAt$settings2;
  const {
    legalTerms,
    userInfo,
    worksAt
  } = data;
  const legalTermsDocumentId = worksAt === null || worksAt === void 0 || (_worksAt$settings = worksAt.settings) === null || _worksAt$settings === void 0 ? void 0 : _worksAt$settings.legalTermsDocumentId;
  return {
    id: '',
    isSample: false,
    progressStatus: RelationshipProgressStatus.Sent,
    progressStatusTimestamp: 0,
    title: '',
    createdOn: 0,
    allowManualPayments: false,
    automaticPaymentsEnabled: true,
    approvalFlow: 'proposal',
    coverLetter: '',
    customNotificationText: (worksAt === null || worksAt === void 0 || (_worksAt$settings2 = worksAt.settings) === null || _worksAt$settings2 === void 0 ? void 0 : _worksAt$settings2.introductoryMessages.proposal) || businessSettingsDefaults.introductoryMessages.proposal,
    effectiveDate: null,
    extraClause: null,
    legalTerms: legalTermsDocumentId && legalTerms.find(term => term.id === legalTermsDocumentId) || legalTerms[0] || legalTermsModel.makePdfLegalTerms({
      name: '',
      pdfUniqueName: ''
    }),
    netTerms: 0,
    services: [],
    serviceBundles: {
      bundles: [{
        id: 'bundle1',
        name: 'Essentials',
        services: [serviceModel.makeService({
          name: 'Bookkeeping Services',
          status: serviceModel.makeAwaitingApprovalStatus(),
          cost: makeFixedCost(750),
          billing: serviceModel.makeOngoingBilling(RecurrencePeriodTypes.Monthly),
          description: '{"type":"quill-delta","value":{"ops":[{"insert":"Tracking and recording financial transactions for your business, ensuring that all financial information is accurate and up to date.\\n\\n"},{"attributes":{"underline":true,"bold":true},"insert":"Service scope"},{"insert":"\\nReconciliation of bank and credit card statements up to 100 transactions on a monthly basis"},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"Receipts and payments reconciliation for up to 100 invoices per month"},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"Paperless Payables - Importing invoices and receipts from suppliers via online software"},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"Debtor reminders"},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"Maintain data feeds into your accounting software files"},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"Troubleshooting \u0026 support"},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"\\n"},{"attributes":{"underline":true,"bold":true},"insert":"Timeframe"},{"insert":"\\nThe listed items in the scope will be provided on a weekly basis, throughout the month.\\n\\n"},{"attributes":{"underline":true,"bold":true},"insert":"Tools used \u0026 limitations"},{"insert":"\\nThis package includes a subscription to [an accounting system].\\n"}]}}'
        }), serviceModel.makeService({
          name: 'Software Setup and Training',
          status: serviceModel.makeAwaitingApprovalStatus(),
          cost: makeFixedCost(500),
          billing: makeBillingOnApproval(),
          description: '{"type":"quill-delta","value":{"ops":[{"insert":"Software setup and training of software package."},{"insert":"\\n\\n"},{"attributes":{"underline":true,"bold":true},"insert":"Service scope"},{"insert":"\\n"},{"insert":"Set up of new account in Software"},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"Full software training for 2 hours with your team optionally recorded"},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"\\n"},{"attributes":{"underline":true,"bold":true},"insert":"Timeframe"},{"insert":"\\n"},{"insert":"The delivery timeframe will depend on your team\'s availability and progress."},{"insert":"\\n"}]}}'
        }), serviceModel.makeService({
          name: 'Financial & Management Reporting',
          status: serviceModel.makeAwaitingApprovalStatus(),
          description: '{"type":"quill-delta","value":{"ops":[{"insert":"We will provide you with quarterly management reporting, in addition to your regular bookkeeping services."},{"insert":"\\n\\n"},{"attributes":{"underline":true,"bold":true},"insert":"Service scope"},{"insert":"\\n"},{"insert":"Income statements (Profit \u0026 Loss account)"},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"Cash summary"},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"Balance Sheet"},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"Accounts Receivables due and Aged Debtors details"},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"Accounts Payables due and Aged Creditors details"},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"A Board Report"},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"A contribution analysis report (by project and/or client)"},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"\\n"},{"attributes":{"underline":true,"bold":true},"insert":"Timeframe"},{"insert":"\\n"},{"insert":"The listed items in the scope will be provided by [a specific date], per client request."},{"insert":"\\n\\n"},{"attributes":{"underline":true,"bold":true},"insert":"Tools / limitations / notes"},{"insert":"\\n"},{"insert":"Based on the information being entered and reconciled in an accounting software package."},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"Reports are provided in a format suitable for presentation to your stakeholders."},{"attributes":{"list":"bullet"},"insert":"\\n"}]}}',
          billing: makeBillingOnApproval(),
          cost: makeFixedCost(1000),
          participation: serviceModel.makeOptionalParticipation(false)
        }), serviceModel.makeService({
          name: 'Payroll Services',
          status: serviceModel.makeAwaitingApprovalStatus(),
          description: '{"type":"quill-delta","value":{"ops":[{"insert":"Recording and tracking employee timesheets, pay calculation, and distribution of direct deposits and checks."},{"insert":"\\n\\n"},{"attributes":{"underline":true,"bold":true},"insert":"Service scope"},{"insert":"\\n"},{"insert":"Calculate and process payroll for up to 30 employees using a payroll program"},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"Calculate and track vacation, sick leave, and other benefits"},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"Provide your employees with direct deposit"},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"Calculate federal and state payroll taxes based on the frequency of deposits you\'ve selected"},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"Preparation and filing of quarterly federal payroll reports (940 and 941)"},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"Workers Compensation payroll reporting"},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"Online access to paychecks"},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"Prepare Forms W-2 and W-3, file electronically, and print duplicate copies as needed at year end"},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"\\n"},{"attributes":{"underline":true,"bold":true},"insert":"Timeframe"},{"insert":"\\n"},{"insert":"The listed items in the scope will be performed throughout the month. "},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"Payroll calculations will be performed on a bi-weekly basis. "},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"Employee salaries will be sent on the 9th of each month."},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"\\n"},{"attributes":{"underline":true,"bold":true},"insert":"Tools / limitations / notes"},{"insert":"\\n"},{"insert":"Using a payroll software [system name]."},{"insert":"\\n"}]}}',
          billing: makeBillingOnApproval(),
          cost: makeFixedCost(150),
          participation: serviceModel.makeOptionalParticipation(false)
        }), serviceModel.makeService({
          name: 'Tax planning',
          status: serviceModel.makeAwaitingApprovalStatus(),
          description: '{"type":"quill-delta","value":{"ops":[{"insert":"Tax assistance, reduction of tax liability through exemptions, deductions, and benefits."},{"insert":"\\n\\n"},{"attributes":{"underline":true,"bold":true},"insert":"Service scope"},{"insert":"\\n"},{"insert":"Periodic planning meetings to discuss to discuss tax-saving opportunities."},{"insert":"\\n\\n"},{"attributes":{"underline":true,"bold":true},"insert":"Timeframe"},{"insert":"\\n"},{"insert":"The delivery timeframe will depend on your team\'s availability and progress."},{"insert":"\\n"}]}}',
          billing: makeBillingOnApproval(),
          cost: makeFixedCost(180),
          participation: serviceModel.makeOptionalParticipation(false)
        })]
      }, {
        id: 'bundle2',
        name: 'Core',
        services: [serviceModel.makeService({
          name: 'Software Setup and Training',
          status: serviceModel.makeAwaitingApprovalStatus(),
          cost: makeFixedCost(500),
          billing: makeBillingOnApproval(),
          description: '{"type":"quill-delta","value":{"ops":[{"insert":"Software setup and training of software package."},{"insert":"\\n\\n"},{"attributes":{"underline":true,"bold":true},"insert":"Service scope"},{"insert":"\\n"},{"insert":"Set up of new account in Software"},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"Full software training for 2 hours with your team optionally recorded"},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"\\n"},{"attributes":{"underline":true,"bold":true},"insert":"Timeframe"},{"insert":"\\n"},{"insert":"The delivery timeframe will depend on your team\'s availability and progress."},{"insert":"\\n"}]}}'
        }), serviceModel.makeService({
          name: 'Budgeting & Forecasting Services',
          status: serviceModel.makeAwaitingApprovalStatus(),
          description: '{"type":"quill-delta","value":{"ops":[{"insert":"Monthly forecasts and budgets for the next 12 months."},{"insert":"\\n\\n"},{"attributes":{"underline":true,"bold":true},"insert":"Service scope"},{"insert":"\\n"},{"insert":"Cash flow forecast for the period"},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"Income statement (profit and loss) forecast for the period"},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"Balance sheet forecast for the period"},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"\\n"},{"attributes":{"underline":true,"bold":true},"insert":"Timeframe"},{"insert":"\\n"},{"insert":"The listed items in the scope will be performed throughout the month leading up to the next quarter, on a quarterly basis."},{"insert":"\\n\\n"},{"attributes":{"underline":true,"bold":true},"insert":"Tools / limitations / notes"},{"insert":"\\n"},{"insert":"This will be in a format suitable for presentation to your stakeholders."},{"insert":"\\n"}]}}',
          billing: serviceModel.makeOngoingBilling(RecurrencePeriodTypes.Monthly),
          cost: makeFixedCost(200)
        }), serviceModel.makeService({
          name: 'Financial & Management Reporting',
          status: serviceModel.makeAwaitingApprovalStatus(),
          description: '{"type":"quill-delta","value":{"ops":[{"insert":"We will provide you with quarterly management reporting, in addition to your regular bookkeeping services."},{"insert":"\\n\\n"},{"attributes":{"underline":true,"bold":true},"insert":"Service scope"},{"insert":"\\n"},{"insert":"Income statements (Profit \u0026 Loss account)"},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"Cash summary"},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"Balance Sheet"},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"Accounts Receivables due and Aged Debtors details"},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"Accounts Payables due and Aged Creditors details"},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"A Board Report"},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"A contribution analysis report (by project and/or client)"},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"\\n"},{"attributes":{"underline":true,"bold":true},"insert":"Timeframe"},{"insert":"\\n"},{"insert":"The listed items in the scope will be provided by [a specific date], per client request."},{"insert":"\\n\\n"},{"attributes":{"underline":true,"bold":true},"insert":"Tools / limitations / notes"},{"insert":"\\n"},{"insert":"Based on the information being entered and reconciled in an accounting software package."},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"Reports are provided in a format suitable for presentation to your stakeholders."},{"attributes":{"list":"bullet"},"insert":"\\n"}]}}',
          billing: serviceModel.makeOngoingBilling(RecurrencePeriodTypes.Monthly),
          cost: makeFixedCost(500)
        }), serviceModel.makeService({
          name: 'Bookkeeping Services',
          status: serviceModel.makeAwaitingApprovalStatus(),
          cost: makeFixedCost(750),
          billing: serviceModel.makeOngoingBilling(RecurrencePeriodTypes.Monthly),
          description: '{"type":"quill-delta","value":{"ops":[{"insert":"Tracking and recording financial transactions for your business, ensuring that all financial information is accurate and up to date.\\n\\n"},{"attributes":{"underline":true,"bold":true},"insert":"Service scope"},{"insert":"\\nReconciliation of bank and credit card statements up to 100 transactions on a monthly basis"},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"Receipts and payments reconciliation for up to 100 invoices per month"},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"Paperless Payables - Importing invoices and receipts from suppliers via online software"},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"Debtor reminders"},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"Maintain data feeds into your accounting software files"},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"Troubleshooting \u0026 support"},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"\\n"},{"attributes":{"underline":true,"bold":true},"insert":"Timeframe"},{"insert":"\\nThe listed items in the scope will be provided on a weekly basis, throughout the month.\\n\\n"},{"attributes":{"underline":true,"bold":true},"insert":"Tools used \u0026 limitations"},{"insert":"\\nThis package includes a subscription to [an accounting system].\\n"}]}}'
        }), serviceModel.makeService({
          name: 'Payroll Services',
          status: serviceModel.makeAwaitingApprovalStatus(),
          description: '{"type":"quill-delta","value":{"ops":[{"insert":"Recording and tracking employee timesheets, pay calculation, and distribution of direct deposits and checks."},{"insert":"\\n\\n"},{"attributes":{"underline":true,"bold":true},"insert":"Service scope"},{"insert":"\\n"},{"insert":"Calculate and process payroll for up to 30 employees using a payroll program"},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"Calculate and track vacation, sick leave, and other benefits"},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"Provide your employees with direct deposit"},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"Calculate federal and state payroll taxes based on the frequency of deposits you\'ve selected"},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"Preparation and filing of quarterly federal payroll reports (940 and 941)"},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"Workers Compensation payroll reporting"},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"Online access to paychecks"},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"Prepare Forms W-2 and W-3, file electronically, and print duplicate copies as needed at year end"},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"\\n"},{"attributes":{"underline":true,"bold":true},"insert":"Timeframe"},{"insert":"\\n"},{"insert":"The listed items in the scope will be performed throughout the month. "},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"Payroll calculations will be performed on a bi-weekly basis. "},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"Employee salaries will be sent on the 9th of each month."},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"\\n"},{"attributes":{"underline":true,"bold":true},"insert":"Tools / limitations / notes"},{"insert":"\\n"},{"insert":"Using a payroll software [system name]."},{"insert":"\\n"}]}}',
          billing: makeBillingOnApproval(),
          cost: makeFixedCost(150),
          participation: serviceModel.makeOptionalParticipation(false)
        })]
      }, {
        id: 'bundle3',
        name: 'Elite',
        services: [serviceModel.makeService({
          name: 'Elite Subscription',
          status: serviceModel.makeAwaitingApprovalStatus(),
          cost: makeFixedCost(2500),
          billing: serviceModel.makeOngoingBilling(RecurrencePeriodTypes.Monthly),
          description: '{"type":"quill-delta","value":{"ops":[{"insert":"Software setup and training of software package."},{"insert":"\\n\\n"},{"attributes":{"underline":true,"bold":true},"insert":"Service scope"},{"insert":"\\n"},{"insert":"Set up of new account in Software"},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"Full software training for 2 hours with your team optionally recorded"},{"attributes":{"list":"bullet"},"insert":"\\n"},{"insert":"\\n"},{"attributes":{"underline":true,"bold":true},"insert":"Timeframe"},{"insert":"\\n"},{"insert":"The delivery timeframe will depend on your team\'s availability and progress."},{"insert":"\\n"}]}}'
        }), serviceModel.makeService({
          name: 'Tax planning',
          status: serviceModel.makeAwaitingApprovalStatus(),
          description: '{"type":"quill-delta","value":{"ops":[{"insert":"Tax assistance, reduction of tax liability through exemptions, deductions, and benefits."},{"insert":"\\n\\n"},{"attributes":{"underline":true,"bold":true},"insert":"Service scope"},{"insert":"\\n"},{"insert":"Periodic planning meetings to discuss to discuss tax-saving opportunities."},{"insert":"\\n\\n"},{"attributes":{"underline":true,"bold":true},"insert":"Timeframe"},{"insert":"\\n"},{"insert":"The delivery timeframe will depend on your team\'s availability and progress."},{"insert":"\\n"}]}}',
          billing: makeBillingOnApproval(),
          cost: makeFixedCost(180),
          participation: serviceModel.makeOptionalParticipation(false)
        })]
      }]
    },
    signedOn: null,
    status: RelationshipStatus.Pending,
    suggestedCustomerName: null,
    signature: null,
    statusTimestamp: 0,
    terminatedOn: null,
    changes: null,
    version: '',
    paymentMethod: null,
    requirePaymentMethod: true,
    adHocChargeStatus: {
      numAwaitingApproval: 0
    },
    invoiceStatus: {
      numPaymentActionRequired: 0,
      numRequiresPaymentApproval: 0
    },
    serviceStatus: {
      numAmended: 0
    },
    amendmentsApprovalMethod: businessSettingsDefaults.amendmentsApprovalMethod,
    invoicesApprovalMethod: businessSettingsDefaults.invoicesApprovalMethod,
    customer: getDefaultCustomer(),
    vendor: getVendor(worksAt),
    buyer: getDefaultBuyer(),
    clientContactId: null,
    createdBy: defaultCreatedBy,
    feeControls: {
      coverCreditCardFees: false,
      coverDirectDebitFees: false
    },
    notificationStatus: [],
    seller: getSeller(userInfo),
    reviewers: [],
    signatories: [],
    additionalApprovalRecords: null,
    introVideo: makeIntroVideoNone(),
    customerSelections: null
  };
}
function getVendor(worksAt) {
  const {
    branding,
    id,
    logoUrl,
    name,
    postalAddress
  } = worksAt !== null && worksAt !== void 0 ? worksAt : {};
  const {
    addressOne,
    addressTwo,
    city,
    country,
    postalCode,
    state
  } = postalAddress !== null && postalAddress !== void 0 ? postalAddress : {};
  return {
    branding: branding || makeDefaultBranding(),
    id: id || '',
    logoUrl: logoUrl || null,
    name: name || '',
    stripeAccountId: '',
    postalAddress: {
      addressOne: addressOne || null,
      addressTwo: addressTwo || null,
      city: city || null,
      country: country || null,
      postalCode: postalCode || null,
      state: state || null
    }
  };
}
function getSeller(userInfo) {
  const user = userInfo !== null && userInfo !== void 0 ? userInfo : {};
  return {
    id: user.id || '',
    firstName: user.firstName || null,
    lastName: user.lastName || null,
    email: user.email || '',
    phone: user.phone || null
  };
}
function getDefaultBuyer() {
  return {
    id: '',
    email: '',
    firstName: 'First name',
    lastName: 'Last name',
    phone: ''
  };
}
function getDefaultCustomer(name) {
  return {
    id: '',
    name: name !== null && name !== void 0 ? name : 'Company name'
  };
}