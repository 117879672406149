import { Decimal } from 'decimal.js';
function applyDiscount(rate, discount) {
  const decimalRate = new Decimal(rate);
  if (!discount) {
    return decimalRate;
  } else {
    return discount.switch({
      whenValue: () => decimalRate.sub(discount.amount()),
      whenPercent: () => decimalRate.times(new Decimal(100).sub(discount.amount()).div(100))
    });
  }
}
function calculateFinalPrice(rate, discount, quantity) {
  const discountedRate = applyDiscount(rate, discount);
  return quantity != null ? discountedRate.mul(quantity) : discountedRate;
}
function formatNumberWithCommas(num) {
  const decimal = new Decimal(num);
  const str = decimal.toFixed(decimal.isInteger() ? 0 : 2);
  return str.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
function formatDollarAmount(amount) {
  const decimal = new Decimal(amount);
  const isNegative = decimal.lessThan(0);
  return "".concat(isNegative ? '-' : '', "$").concat(formatNumberWithCommas(isNegative ? decimal.abs().toNumber() : decimal));
}
function getPriceRangeText(_ref) {
  let {
    minPrice,
    maxPrice
  } = _ref;
  return minPrice != null && !minPrice.equals(0) ? "".concat(formatDollarAmount(minPrice.toNumber()), " - ").concat(formatDollarAmount(maxPrice.toNumber())) : "Up to ".concat(formatDollarAmount(maxPrice.toNumber()));
}

/** Turns an integer of cents to a floating-point number of dollars. */
const centsToDollars = function centsToDollars() {
  let cents = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  return new Decimal(cents).div(100).toDecimalPlaces(2).toNumber();
};

/** Turns a floating-point number of dollars to an integer of cents. */
const dollarsToCents = dollars => new Decimal(dollars).mul(100).toDecimalPlaces(0).toNumber();
export const currencyUtils = {
  applyDiscount,
  calculateFinalPrice,
  formatNumberWithCommas,
  formatDollarAmount,
  getPriceRangeText,
  centsToDollars,
  dollarsToCents
};