// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.yyisC{text-align:start}.rvTek{display:flex;align-items:baseline;gap:16px}.uKWmh:not(:last-child){margin-block-end:32px}.zE6P8{margin-block-end:16px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `yyisC`,
	"indicatedText": `rvTek`,
	"section": `uKWmh`,
	"step": `zE6P8`
};
export default ___CSS_LOADER_EXPORT___;
