const moveItem = (item, _ref, intent) => {
  let [...items] = _ref;
  const currentIndex = items.findIndex(_ref2 => {
    let {
      id
    } = _ref2;
    return id === item.id;
  });
  const newIndex = currentIndex + (intent === 'up' ? -1 : 1);
  const canMoveUp = intent === 'up' && 0 < currentIndex;
  const canMoveDown = intent === 'down' && currentIndex < items.length - 1;
  if (!Number.isNaN(currentIndex) && (canMoveUp || canMoveDown)) {
    const temp = items[currentIndex];
    items[currentIndex] = items[newIndex];
    items[newIndex] = temp;
  }
  return items;
};
export default moveItem;