import { IntegrationSource } from "../../types/api.model";
export const getPMDetails = pm => ({
  name: {
    [IntegrationSource.Karbon]: 'Karbon',
    [IntegrationSource.ClientHub]: 'Client Hub',
    [IntegrationSource.FinancialCents]: 'Financial Cents'
  }[pm],
  item: {
    [IntegrationSource.Karbon]: 'work item',
    [IntegrationSource.ClientHub]: 'job',
    [IntegrationSource.FinancialCents]: 'project'
  }[pm],
  company: {
    [IntegrationSource.Karbon]: 'organization',
    [IntegrationSource.ClientHub]: 'organization',
    [IntegrationSource.FinancialCents]: 'client'
  }[pm],
  contact: {
    [IntegrationSource.Karbon]: 'contact',
    [IntegrationSource.ClientHub]: 'client',
    [IntegrationSource.FinancialCents]: 'contact'
  }[pm],
  template: {
    [IntegrationSource.Karbon]: 'work template',
    [IntegrationSource.ClientHub]: 'job template',
    [IntegrationSource.FinancialCents]: 'template'
  }[pm],
  articleLink: {
    [IntegrationSource.Karbon]: '9881648-karbon',
    [IntegrationSource.ClientHub]: '9920686-client-hub',
    [IntegrationSource.FinancialCents]: '9881503-financial-cents'
  }[pm],
  nameForTestID: {
    [IntegrationSource.Karbon]: 'karbon',
    [IntegrationSource.ClientHub]: 'client-hub',
    [IntegrationSource.FinancialCents]: 'financial-cents'
  }[pm]
});