// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Dek5r{display:flex;flex-direction:column;gap:8px}.Dek5r.TMNGb{gap:4px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"side": `Dek5r`,
	"mobile": `TMNGb`
};
export default ___CSS_LOADER_EXPORT___;
