export function richTextContent(legalTerms) {
  if (isPDF(legalTerms)) {
    return [];
  }
  return typeof legalTerms.richTextContent[0] === 'string' ? legalTerms.richTextContent.map(v => JSON.parse(v)) : legalTerms.richTextContent;
}
export function isPDF(legalTerms) {
  return legalTerms.contentType === 'pdf';
}
export function isRichText(legalTerms) {
  return legalTerms.contentType === 'richText';
}
export function name(legalTerms) {
  return legalTerms.name;
}