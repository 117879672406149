// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Y18tK{height:48px;width:48px;border-radius:50%;color:#9393b1;margin:auto;margin-top:12px;margin-bottom:18px;position:relative}.Y18tK.CNhaz{border:1.5px solid #9393b1}.Y18tK.S165B{outline:1px dashed #8296ff;outline-offset:5px}.yzNKn{height:100%;width:100%;border-radius:50%;display:flex;align-items:center;justify-content:center}.FZrw9{display:none}.AJSZ1{height:100%;width:100%;object-fit:contain}.bypv9{position:absolute;top:-10px;right:-10px;transform:rotate(45deg);background:#fff}.h0mGh{position:absolute;top:0;left:0;height:100%;width:100%;display:flex;align-items:center;justify-content:center;background:rgba(255,255,255,.8);border-radius:50%}.Fraoi,.Fraoi button span{line-height:16px;text-align:center}.aEFqG{position:relative;height:120px;border:1px solid #e4e4ec;border-radius:12px}.aEFqG.S165B{outline:1px dashed #8296ff;outline-offset:5px}.aEFqG.MKAJX{border:0}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blue45": `#fcfcff`,
	"blue48": `#f5f7fd`,
	"blue50": `#f3f5ff`,
	"blue300": `#dae0ff`,
	"blue500": `#c0caff`,
	"blue600": `#a1aef5`,
	"blue700": `#8296ff`,
	"blue800": `#6176e7`,
	"dark50": `#fcfcfd`,
	"dark70": `#f4f4f7`,
	"dark100": `#eaeaef`,
	"dark120": `#e4e4ec`,
	"dark200": `#d4d4e0`,
	"dark300": `#bfbfd0`,
	"dark400": `#a9a9c1`,
	"dark500": `#9393b1`,
	"dark600": `#7e7ea1`,
	"dark700": `#696992`,
	"dark800": `#535382`,
	"dark900": `#282863`,
	"green45": `#eff6fa`,
	"green50": `#f3fcfa`,
	"green300": `#b6ebdf`,
	"green500": `#86ddca`,
	"green700": `#56d0b5`,
	"green800": `#36b89b`,
	"green900": `#089475`,
	"orange50": `#fdf2dc`,
	"orange300": `#fadea8`,
	"orange500": `#f9d797`,
	"orange700": `#f7ca75`,
	"orange800": `#f5bd52`,
	"orange900": `#dd9306`,
	"purple50": `#e1d9fe`,
	"purple300": `#c3b3fc`,
	"purple500": `#a58efb`,
	"purple700": `#8768f9`,
	"purple800": `#6942f8`,
	"purple900": `#4520cc`,
	"red200": `#fdf0f0`,
	"red300": `#fee3e3`,
	"red500": `#f9d5d5`,
	"red700": `#f3aaaa`,
	"red900": `#eb7272`,
	"red950": `#e86161`,
	"red970": `#dd4c4c`,
	"white": `#fff`,
	"defaultTextStandard": `#282863`,
	"defaultTextSecondary": `#696992`,
	"defaultButtonPrimary": `#6942f8`,
	"buttonPrimary": `var(--button-primary, #6942f8)`,
	"buttonPrimaryDark": `var(--button-primary-dark, #4520cc)`,
	"buttonDisabled": `var(--button-disabled, #bfbfd0)`,
	"buttonDisabledDark": `var(--button-disabled-dark, #a9a9c1)`,
	"defaultButtonDisabled": `#bfbfd0`,
	"defaultStepperCompleted": `#089475`,
	"defaultStepperIncomplete": `#d4d4e0`,
	"defaultButtonSecondary": `#fcfcfd`,
	"defaultBgStandard": `#f3f5ff`,
	"defaultBgLight": `#fff`,
	"circle": `Y18tK`,
	"withBorder": `CNhaz`,
	"draggedOver": `S165B`,
	"add": `yzNKn`,
	"input": `FZrw9`,
	"image": `AJSZ1`,
	"cancel": `bypv9`,
	"loading": `h0mGh`,
	"uploadLogoBox": `Fraoi`,
	"uploadBox": `aEFqG`,
	"hasLogo": `MKAJX`
};
export default ___CSS_LOADER_EXPORT___;
