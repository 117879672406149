import { formatDate } from "./time";
export class AccountantModel {
  constructor(dto) {
    this.dto = dto;
  }
  id() {
    return this.dto.id;
  }
  companyName() {
    return this.dto.companyName;
  }
  formattedDate() {
    return formatDate(this.dto.date);
  }
  isInvited() {
    return this.dto.status === 'invited';
  }
  isActive() {
    return this.dto.status === 'active';
  }
  invitedEmail() {
    return this.dto.invited.email;
  }
}