// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.xGPfT{display:flex;gap:40px}.xGPfT>*{flex:1}.rV3rf{margin-top:16px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ccExpirationRow": `xGPfT`,
	"error": `rV3rf`
};
export default ___CSS_LOADER_EXPORT___;
