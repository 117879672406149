import { timeModel } from "../../../models";
export const formatDigitalSignature = approvalRecord => {
  return {
    signature: approvalRecord.signature,
    on: "on ".concat(timeModel.formatDateWithTime(approvalRecord.timestamp, true)),
    ip: "(IP: ".concat(approvalRecord.ip, ")")
  };
};
export const getSortedFormattedDigitalSignatures = (primaryApprovalRecord, signatoriesApprovalRecord) => {
  return [primaryApprovalRecord, ...signatoriesApprovalRecord].sort((a, b) => {
    return a.timestamp - b.timestamp;
  }).map(formatDigitalSignature);
};