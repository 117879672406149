function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { InvoiceStatus } from "./model";
import Decimal from 'decimal.js';
import { InvoiceIntegrationModel } from "../InvoiceIntegrationModel";
export function fromDTO(isVendor, dto) {
  const status = isVendor ? dto.vendorStatus : dto.customerStatus;
  return {
    id: dto.id,
    netTerms: dto.netTerms,
    createdOn: dto.createdOn,
    issuedOn: dto.issuedOn,
    dueDate: dto.dueDate,
    customerId: dto.customerId,
    isRetryable: dto.isRetryable,
    pdfExists: dto.pdfExists,
    totalAmount: dto.totalAmount,
    paymentMethodStatus: dto.paymentMethodStatus,
    formattedNumber: dto.formattedNumber,
    status: status.type,
    approvalMethod: status.type === InvoiceStatus.RequiresPaymentApproval ? status.requiresPaymentApproval.approvalMethod : null,
    paymentFailure: status.type === InvoiceStatus.PaymentFailed ? {
      code: status.paymentFailed.failureCode,
      message: status.paymentFailed.message
    } : null,
    paymentStop: status.type === InvoiceStatus.PaymentStopped ? status.paymentStopped : null,
    paymentDisputed: status.type === InvoiceStatus.PaymentDisputed ? {
      code: status.paymentDisputed.failureCode,
      message: status.paymentDisputed.message
    } : null,
    processingAction: status.type === InvoiceStatus.PaymentActionRequired ? status.paymentActionRequired.processingAction : null,
    displayedStatus: dto.displayStatus,
    paymentStatus: isVendor ? dto.vendorPaymentDisplayStatus : dto.customerPaymentDisplayStatus,
    isZeroAmount: dto.isTotalAmountZero,
    clientContactId: '',
    integration: InvoiceIntegrationModel.fromScoutDTO(dto.integration),
    items: dto.items.map(fromItemDTO),
    relationship: dto.relationship,
    rawTotalAmount: new Decimal(dto.rawTotalAmount).div(100).toDP(2),
    rawDueDate: new Date(dto.rawDueDate),
    rawIssuedOn: new Date(dto.rawIssuedOn),
    rawCreatedOn: new Date(dto.createdOn),
    number: dto.number
  };
}
export function fromItemDTO(dto) {
  return _objectSpread(_objectSpread({}, dto), {}, {
    serviceId: null,
    serviceTemplateId: null,
    adHocChargeId: null
  });
}