// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jOnQB{flex:1;display:grid;grid-template-columns:repeat(var(--num-cols), 1fr);column-gap:7px;row-gap:8px}.jOnQB.CMFa6{grid-template-rows:auto 252px}.jOnQB.L9Qdg{grid-template-rows:auto 302px}.huQ3_{margin-bottom:12px;display:flex;align-items:center;gap:8px}.QoYqc{grid-column-start:1;grid-column-end:calc(var(--num-cols) + 1)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grid": `jOnQB`,
	"mBottomHeight": `CMFa6`,
	"lBottomHeight": `L9Qdg`,
	"title": `huQ3_`,
	"fullWidthCell": `QoYqc`
};
export default ___CSS_LOADER_EXPORT___;
