import { integrationModel } from "../../../models";
export class CardHandler {
  constructor(ui, integration, server) {
    this.ui = ui;
    this.integration = integration;
    this.server = server;
  }
  handleClick(route) {
    integrationModel.isLinked(this.integration) ? this.ui.goToPage(route) : this.ui.requestApiKey(this);
  }
  getActionText() {
    return integrationModel.isLinked(this.integration) ? 'Manage connection' : 'Connect';
  }
  async saveApiKey(apiKey) {
    await this.server.saveApiKey(apiKey);
    this.ui.refetchIntegrations();
  }
}