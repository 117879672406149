// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._1dm74{margin-block-start:4px;font-family:"AlexBrush-Regular","Qwigley",cursive;font-weight:400;font-size:24px}.h1PyD{display:flex;gap:12px;flex-direction:row;align-items:center}.gc_Kp{display:flex;margin-top:6px;flex-direction:column;flex:1 0 auto}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"signedSignature": `_1dm74`,
	"digitalSignature": `h1PyD`,
	"detailsWrapper": `gc_Kp`
};
export default ___CSS_LOADER_EXPORT___;
