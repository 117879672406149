// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nqP5V{background:linear-gradient(297.2deg, #F0EFFA 16.5%, #FCFCFF 100%);width:100vw;height:100vh;display:grid;justify-content:center;align-content:center}.aByW3{width:615px;display:flex;flex-direction:column;justify-content:center;align-content:center;align-items:center}.Jpa9f{text-align:center;width:506px}.onLbr{margin-top:20px;width:fit-content}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `nqP5V`,
	"container": `aByW3`,
	"text": `Jpa9f`,
	"button": `onLbr`
};
export default ___CSS_LOADER_EXPORT___;
