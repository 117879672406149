// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.by3Ru{display:flex;flex-direction:column;gap:16px;padding:0 36px}.kj1fb{display:flex;flex-direction:row;gap:20px}.LJBpB{display:flex;flex-direction:column;gap:4px}.A_Kdi{cursor:pointer;user-select:none}.sHMDb{padding:10px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `by3Ru`,
	"nameSection": `kj1fb`,
	"section": `LJBpB`,
	"removeOther": `A_Kdi`,
	"businessTypeOption": `sHMDb`
};
export default ___CSS_LOADER_EXPORT___;
