// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.zOup3{padding:20px 36px}.e2PTb{display:flex;justify-content:center}.H5NSg{display:flex;flex-direction:column;gap:4px;width:320px;margin:10px 0;border-radius:4px}.H5NSg.ZmFG0{background:#fcfcfd;box-shadow:0px 4px 12px rgba(40,40,99,.06);padding:8px 0}.H5NSg.ZmFG0 .e2PTb{display:flex;flex-direction:column;gap:4px;padding:0 16px}.H5NSg.Qw1M_{background-color:#e4e4ec;padding:8px 16px}.H5NSg .CEHsw{margin:8px 0;height:1px;background-color:#eaeaef}.H5NSg .w66lM{padding:8px 16px;display:flex;flex-direction:column;gap:24px}.H5NSg .w66lM .sxnoZ{display:flex;flex-direction:column;gap:8px}.H5NSg .w66lM .sxnoZ .k_JBC{display:flex;flex-direction:row;justify-content:space-between}.Owx8j{height:600px;display:flex;align-items:center}.jWGF3{width:100%;display:flex;flex-direction:column;align-items:center;gap:24px}.TOLM1{width:480px;padding:20px;display:flex;flex-direction:column;gap:8px}.OG1dV{display:flex;flex-direction:row;gap:16px;padding-top:20px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blue45": `#fcfcff`,
	"blue48": `#f5f7fd`,
	"blue50": `#f3f5ff`,
	"blue300": `#dae0ff`,
	"blue500": `#c0caff`,
	"blue600": `#a1aef5`,
	"blue700": `#8296ff`,
	"blue800": `#6176e7`,
	"dark50": `#fcfcfd`,
	"dark70": `#f4f4f7`,
	"dark100": `#eaeaef`,
	"dark120": `#e4e4ec`,
	"dark200": `#d4d4e0`,
	"dark300": `#bfbfd0`,
	"dark400": `#a9a9c1`,
	"dark500": `#9393b1`,
	"dark600": `#7e7ea1`,
	"dark700": `#696992`,
	"dark800": `#535382`,
	"dark900": `#282863`,
	"green45": `#eff6fa`,
	"green50": `#f3fcfa`,
	"green300": `#b6ebdf`,
	"green500": `#86ddca`,
	"green700": `#56d0b5`,
	"green800": `#36b89b`,
	"green900": `#089475`,
	"orange50": `#fdf2dc`,
	"orange300": `#fadea8`,
	"orange500": `#f9d797`,
	"orange700": `#f7ca75`,
	"orange800": `#f5bd52`,
	"orange900": `#dd9306`,
	"purple50": `#e1d9fe`,
	"purple300": `#c3b3fc`,
	"purple500": `#a58efb`,
	"purple700": `#8768f9`,
	"purple800": `#6942f8`,
	"purple900": `#4520cc`,
	"red200": `#fdf0f0`,
	"red300": `#fee3e3`,
	"red500": `#f9d5d5`,
	"red700": `#f3aaaa`,
	"red900": `#eb7272`,
	"red950": `#e86161`,
	"red970": `#dd4c4c`,
	"white": `#fff`,
	"defaultTextStandard": `#282863`,
	"defaultTextSecondary": `#696992`,
	"defaultButtonPrimary": `#6942f8`,
	"buttonPrimary": `var(--button-primary, #6942f8)`,
	"buttonPrimaryDark": `var(--button-primary-dark, #4520cc)`,
	"buttonDisabled": `var(--button-disabled, #bfbfd0)`,
	"buttonDisabledDark": `var(--button-disabled-dark, #a9a9c1)`,
	"defaultButtonDisabled": `#bfbfd0`,
	"defaultStepperCompleted": `#089475`,
	"defaultStepperIncomplete": `#d4d4e0`,
	"defaultButtonSecondary": `#fcfcfd`,
	"defaultBgStandard": `#f3f5ff`,
	"defaultBgLight": `#fff`,
	"sh10": `0px 4px 12px rgba(40,40,99,.06)`,
	"sh20": `0px 4px 16px rgba(40,40,99,.08)`,
	"sh40": `0px 4px 24px rgba(40,40,99,.12)`,
	"sh60": `0px 4px 14px rgba(40,40,99,.24)`,
	"content": `zOup3`,
	"header": `e2PTb`,
	"step": `H5NSg`,
	"completedStep": `ZmFG0`,
	"incompleteStep": `Qw1M_`,
	"divider": `CEHsw`,
	"reminders": `w66lM`,
	"reminder": `sxnoZ`,
	"title": `k_JBC`,
	"loaderContainer": `Owx8j`,
	"loader": `jWGF3`,
	"popoverContent": `TOLM1`,
	"popoverActions": `OG1dV`
};
export default ___CSS_LOADER_EXPORT___;
