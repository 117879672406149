// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rnEbo{margin-left:8px}.n5Oh0{list-style:disc;margin-inline-start:16px}.acwZX{margin-top:8px}.UIhYF{margin-bottom:8px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"changes": `rnEbo`,
	"changeItem": `n5Oh0`,
	"approvalInfo": `acwZX`,
	"amendmentDate": `UIhYF`
};
export default ___CSS_LOADER_EXPORT___;
