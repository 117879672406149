// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.foBwr{min-width:210px !important;display:flex;align-items:center;gap:12px}.D4cdv{position:relative}.I89Jo{opacity:0;position:absolute;top:8px;left:0;height:100%;width:100%;z-index:-1}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `foBwr`,
	"previewWrapper": `D4cdv`,
	"input": `I89Jo`
};
export default ___CSS_LOADER_EXPORT___;
