import { endOfDay, startOfWeek, startOfMonth, subMonths, endOfMonth, startOfDay } from 'date-fns';
import { sendErrReport } from "../../../infra/utils";
const noFilterObj = {
  fromDate: null,
  toDate: null
};
export function getFormattedTimeRange(preset, now) {
  const today = endOfDay(now).getTime();
  switch (preset) {
    case 'all-time':
      return noFilterObj;
    case 'today':
      return {
        fromDate: startOfDay(now).getTime(),
        toDate: today
      };
    case 'this-week':
      return {
        fromDate: startOfWeek(today).getTime(),
        toDate: today
      };
    case 'this-month':
      return {
        fromDate: startOfMonth(today).getTime(),
        toDate: today
      };
    case 'previous-month':
      {
        const previousMonth = subMonths(today, 1);
        const startOfPreviousMonth = startOfMonth(previousMonth).getTime();
        const endOfPreviousMonth = endOfMonth(startOfPreviousMonth).getTime();
        return {
          fromDate: startOfPreviousMonth,
          toDate: endOfPreviousMonth
        };
      }
    case 'last-three-months':
      {
        const startOfLastThreeMonth = startOfMonth(subMonths(today, 3)).getTime();
        const endOfPreviousMonth = endOfMonth(subMonths(today, 1)).getTime();
        return {
          fromDate: startOfLastThreeMonth,
          toDate: endOfPreviousMonth
        };
      }
    default:
      sendErrReport("DateFilterPicker - date preset: ".concat(preset, " is unknown and can not be handled"));
      return noFilterObj;
  }
}
export function isInRange(rangeObj, dateObj) {
  const timestamp = dateObj.getTime();
  return (rangeObj.fromDate || -Infinity) <= timestamp && timestamp <= (rangeObj.toDate || Infinity);
}