// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jHm_j{display:flex;flex-direction:row;justify-content:center;width:100%}.iaRew{display:flex;flex-direction:row;justify-content:center;align-items:center;border:#0077c5 2px solid;border-radius:4px;padding:10px 16px;width:400px;height:38px}.iaRew:hover{background-color:rgba(0,119,197,.1)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonContainer": `jHm_j`,
	"button": `iaRew`
};
export default ___CSS_LOADER_EXPORT___;
