import { useEffect } from 'react';
import { useStateInRef } from 'seagull';
export function useInterval(callback, delay) {
  let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  const getState = useStateInRef({
    callback,
    options
  });

  // Set up the interval.
  useEffect(() => {
    function tick() {
      const hasCallback = getState().callback !== null;
      const shouldSuppressCallback = getState().options.suppressWhenDocumentHidden && document.hidden;
      if (hasCallback && !shouldSuppressCallback) {
        getState().callback();
      }
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}