import { ApprovalMethodDateType } from "./model";
export function makeAutoApprovalMethodDate(approveDate) {
  return {
    type: ApprovalMethodDateType.Auto,
    auto: {
      approveDate
    }
  };
}
export function makeManualApprovalMethodDate() {
  return {
    type: ApprovalMethodDateType.Manual
  };
}