import { useEffect } from 'react';
import { useStore, useStoreActions } from "../contexts";
import { selectors } from "../store/state-selectors";

/** Automatically fetches feature-flags for the relevant businessId, if they weren't already fetched. */
export default function FeatureFlagsFetcher() {
  const {
    ff: {
      fetchFeatureFlags
    }
  } = useStoreActions();
  const businessId = useStore(selectors.ff.ffBusinessId);
  const ff = useStore(selectors.ff.featureFlags);
  useEffect(() => {
    if (ff.data === null && !ff.loading && !ff.error) {
      fetchFeatureFlags(businessId);
    }
  }, [ff, businessId]);
  return null;
}