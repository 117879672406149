import { chartColors } from "./chartColors";
export const commonXAxisProps = {
  axisLine: {
    stroke: chartColors.axisLine
  },
  tick: {
    fill: chartColors.axisTicks
  },
  interval: 0,
  // shows all ticks
  tickLine: false,
  fontSize: 12
};
export const commonBarChartProps = {
  barCategoryGap: '30%',
  margin: {
    top: 20,
    left: 6,
    right: 6
  }
};
export const commonCartesianGridProps = {
  vertical: false,
  stroke: chartColors.grid
};
export const commonBarProps = {
  isAnimationActive: false
};