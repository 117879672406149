export const getUICompanyName = draft => {
  var _draft$contactDetails;
  return draft.isSample ? 'Anchor (Sample)' : ((_draft$contactDetails = draft.contactDetails) === null || _draft$contactDetails === void 0 ? void 0 : _draft$contactDetails.companyName) || draft.customerName;
};
const sortById = (a, b) => a.id < b.id ? -1 : 1;
const sortByCompanyName = (a, b) => {
  const noDataA = !a.customerName && !a.title;
  const noDataB = !b.customerName && !b.title;
  if (noDataA && noDataB) {
    return 0;
  }
  if (noDataA) {
    return 1;
  }
  if (noDataB) {
    return -1;
  }
  const nameA = a.customerName.toLowerCase() + (a.title ? " (".concat(a.title.toLowerCase(), ")") : '');
  const nameB = b.customerName.toLowerCase() + (b.title ? " (".concat(b.title.toLowerCase(), ")") : '');
  if (nameA < nameB) {
    return -1;
  } else if (nameA > nameB) {
    return 1;
  } else {
    return 0;
  }
};
const sortByModifiedBy = (a, b) => {
  const modifiedByA = a.lastModifiedBy ? "".concat(a.lastModifiedBy.firstName, " ").concat(a.lastModifiedBy.lastName) : '';
  const modifiedByB = b.lastModifiedBy ? "".concat(b.lastModifiedBy.firstName, " ").concat(b.lastModifiedBy.lastName) : '';
  if (modifiedByA < modifiedByB) {
    return -1;
  } else if (modifiedByA > modifiedByB) {
    return 1;
  } else {
    return 0;
  }
};
const sortByLastModifiedOn = (a, b) => b.lastModifiedOn - a.lastModifiedOn;
export const sortFunctions = {
  0: isAscending => (a, b) => {
    const retval = sortByCompanyName(a, b) || sortByModifiedBy(a, b) || sortById(a, b);
    return isAscending ? -retval : retval;
  },
  1: isAscending => (a, b) => {
    const retval = sortByModifiedBy(a, b) || sortByCompanyName(a, b) || sortById(a, b);
    return isAscending ? -retval : retval;
  },
  2: isAscending => (a, b) => {
    const retval = sortByLastModifiedOn(a, b) || sortById(a, b);
    return isAscending ? -retval : retval;
  }
};