import { formatDateWithTime } from "./time";
export class ProposalTemplateSummaryModel {
  static fromDTO(dto) {
    return new ProposalTemplateSummaryModel(dto);
  }
  static sortByTitle(a, b) {
    const nameA = a.lowercaseTitle();
    const nameB = b.lowercaseTitle();
    if (nameA < nameB) {
      return -1;
    } else if (nameA > nameB) {
      return 1;
    } else return 0;
  }
  constructor(data) {
    this.data = data;
  }
  formattedLastUpdatedOn() {
    return formatDateWithTime(this.data.lastUpdatedOn);
  }
  lastUpdatedOn() {
    return this.data.lastUpdatedOn;
  }
  lowercaseTitle() {
    return this.data.title.toLowerCase();
  }
  id() {
    return this.data.id;
  }
  title() {
    return this.data.title;
  }
}