// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Pje5Y{width:164px;height:64px;margin-bottom:16px}@media(max-width: 560px){.Pje5Y{width:91px;height:26px}}.pRWTl{display:flex;flex-direction:column;align-items:center}.u9jKl{display:flex;flex-direction:column;align-items:center;gap:16px}.xDQVP{justify-content:center}.xDQVP::before{content:"<<"}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mobileMaxWidth": `560px`,
	"tabletMinWidth": `561px`,
	"tabletMaxWidth": `1024px`,
	"desktopMinWidth": `1025px`,
	"smallScreenMaxWidth": `1400px`,
	"largeScreenMinWidth": `1401px`,
	"logo": `Pje5Y`,
	"emailVerifiedMsg": `pRWTl`,
	"footer": `u9jKl`,
	"backButton": `xDQVP`
};
export default ___CSS_LOADER_EXPORT___;
