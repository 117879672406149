// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.jZn7i{width:100%}.jZn7i .s4kJL{height:80%;width:90%;display:flex;justify-content:center;align-items:center;flex-direction:column;font-family:Inter}.jZn7i .s4kJL .WKIKP{font-size:var(--font-size-h5, 22px);line-height:var(--line-height-h5, 32px);font-weight:500;padding-bottom:25px}.jZn7i .s4kJL .nDx4D{font-size:var(--font-size-p1, 16px);line-height:var(--line-height-p1, 24px);font-weight:400}.jZn7i .s4kJL svg{padding:40px 0 0 20px;width:200px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fontFamilyLight": `"Lufga-Regular"`,
	"fontFamilyNormal": `"Lufga-Medium"`,
	"fontFamilyBold": `"Lufga-SemiBold"`,
	"fontSizeH1": `var(--font-size-h1, 48px)`,
	"fontSizeH2": `var(--font-size-h2, 40px)`,
	"fontSizeH3": `var(--font-size-h3, 33px)`,
	"fontSizeH4": `var(--font-size-h4, 28px)`,
	"fontSizeH5": `var(--font-size-h5, 22px)`,
	"fontSizeH6": `var(--font-size-h6, 20px)`,
	"fontSizeP1": `var(--font-size-p1, 16px)`,
	"fontSizeP2": `var(--font-size-p2, 14px)`,
	"fontSizeP3": `var(--font-size-p3, 12px)`,
	"lineHeightH1": `var(--line-height-h1, 64px)`,
	"lineHeightH2": `var(--line-height-h2, 56px)`,
	"lineHeightH3": `var(--line-height-h3, 40px)`,
	"lineHeightH4": `var(--line-height-h4, 40px)`,
	"lineHeightH5": `var(--line-height-h5, 32px)`,
	"lineHeightH6": `var(--line-height-h6, 35px)`,
	"lineHeightP1": `var(--line-height-p1, 24px)`,
	"lineHeightP2": `var(--line-height-p2, 18px)`,
	"lineHeightP3": `var(--line-height-p3, 16px)`,
	"billingDashboard": `jZn7i`,
	"emptyStateWrapper": `s4kJL`,
	"title": `WKIKP`,
	"subTitle": `nDx4D`
};
export default ___CSS_LOADER_EXPORT___;
