import { serviceModel } from "../../../../models";
export function splitServices(services, isVendor) {
  const activeServices = [];
  const inactiveServices = []; // stopped or completed services

  for (const service of services) {
    if (serviceModel.isTerminated(service.status) || serviceModel.isCompleted(service.status)) {
      inactiveServices.push(service);
    } else if (!serviceModel.isCanceled(service.status)) {
      activeServices.push(service);
    }
  }

  // if a client views this page to approve amendments, sort ongoing services in this order:
  // 1. amended
  // 2. awaiting approval
  // 3. all the rest...
  if (!isVendor) {
    activeServices.sort((a, b) => serviceStatusSortRank(a) - serviceStatusSortRank(b));
  }
  return [activeServices, inactiveServices];
}
function serviceStatusSortRank(service) {
  if (serviceModel.isAmended(service.status)) {
    return 0;
  } else if (serviceModel.isAwaitingApproval(service.status)) {
    return 1;
  } else {
    return 2;
  }
}