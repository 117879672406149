import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
export function useUtmsParams() {
  const {
    search
  } = useLocation();
  return useCallback(() => {
    const utms = {};
    new URLSearchParams(search).forEach((value, key) => {
      if (key.toLowerCase().includes('utm_')) {
        utms[key] = value;
      }
    });
    return utms;
  }, [search]);
}