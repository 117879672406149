// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Kn8q6{width:288px;height:300px;padding-top:32px;padding-bottom:16px;display:flex;flex-direction:column;align-items:center;justify-content:space-between}.Da7CU{padding:0 24px;min-height:64px}.CjDEq{padding:0 24px;text-align:center}.mHQOg{min-height:58px;display:flex;flex-direction:column;align-items:center;gap:8px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": `Kn8q6`,
	"icon": `Da7CU`,
	"text": `CjDEq`,
	"bottom": `mHQOg`
};
export default ___CSS_LOADER_EXPORT___;
