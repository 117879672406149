// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AjVLv{height:100%;display:flex;align-items:center;justify-content:center;text-align:center}.H9jxg{height:64px;margin:auto;margin-bottom:16px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"invalidWrapper": `AjVLv`,
	"logo": `H9jxg`
};
export default ___CSS_LOADER_EXPORT___;
