import { useStore, useStoreActions } from "../store/store-context";
import { useWithdrawToEditProposal } from "./useWithdrawToEditProposal";
export function useContactActions() {
  var _userInfo$worksAt;
  const {
    withdrawToEditProposalById
  } = useWithdrawToEditProposal();
  const {
    api
  } = useStoreActions();
  const userInfo = useStore(state => state.userInfo);
  const businessId = userInfo === null || userInfo === void 0 || (_userInfo$worksAt = userInfo.worksAt) === null || _userInfo$worksAt === void 0 ? void 0 : _userInfo$worksAt.id;
  async function fetchContactProposals(contactId) {
    if (!businessId) throw new Error('No business');
    return api.relationship.getSummaries({
      businessId,
      type: 'proposal',
      isVendor: true,
      search: '',
      clientContactId: contactId
    });
  }
  async function withdrawToEditContactProposals(contactId) {
    const contactProposals = await fetchContactProposals(contactId);
    return Promise.all(contactProposals.map(proposal => withdrawToEditProposalById(proposal.id)));
  }
  return {
    withdrawToEditContactProposals,
    fetchContactProposals
  };
}