import Decimal from 'decimal.js';
import { fromDatestampObject } from "../time";
import { makeResponse, make, makePeriod } from "./makers";
export function fromResponseDTO(responseDto) {
  const result = makeResponse();
  Object.entries(responseDto).forEach(_ref => {
    let [serviceId, dto] = _ref;
    result[serviceId] = fromDTO(dto);
  });
  return result;
}
function fromDTO(dto) {
  return make(dto.billableState, dto.periods.map(fromBillingPeriodDTO), fromDatestampObject(dto.minDate), fromDatestampObject(dto.maxDate));
}
function fromBillingPeriodDTO(dto) {
  return makePeriod(fromDatestampObject(dto.startDate), fromDatestampObject(dto.endDate), new Decimal(dto.billedQuantity), new Decimal(dto.scheduledQuantity));
}