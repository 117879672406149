import { useState } from 'react';

/**
 * A signal is an undefined state, that can be set to a value and immediately set back to undefined.
 * You can use a signal as a prop to a component that will react to it with a useEffect.
 */
export function useSignal() {
  const [state, setState] = useState();
  function triggerSignal(value) {
    setState(value);
    setTimeout(() => setState(undefined));
  }
  return [state, triggerSignal];
}