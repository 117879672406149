function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { parseJSON } from 'date-fns';
export class HistoricAgreementStatsModel {
  static makeEmpty() {
    let {
      date
    } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return new HistoricAgreementStatsModel({
      businessId: '',
      date: (date || new Date()).toISOString(),
      activeAgreementsCount: 0,
      activeAgreementsMonthlyRecurringValue: '$0',
      proposalsCount: 0,
      proposalsMonthlyRecurringValue: '$0'
    });
  }
  constructor(dto) {
    this.dto = dto;
    this._date = parseJSON(dto.date);
  }
  date() {
    return this._date;
  }
  agreementsCount() {
    return this.dto.activeAgreementsCount;
  }
  agreementsDollarAmount() {
    return this.dto.activeAgreementsMonthlyRecurringValue.split('.')[0];
  }
  proposalsCount() {
    return this.dto.proposalsCount;
  }
  proposalsDollarAmount() {
    return this.dto.proposalsMonthlyRecurringValue.split('.')[0];
  }
  setActiveAgreementsCount(count) {
    return new HistoricAgreementStatsModel(_objectSpread(_objectSpread({}, this.dto), {}, {
      activeAgreementsCount: count
    }));
  }
  setProposalsCount(count) {
    return new HistoricAgreementStatsModel(_objectSpread(_objectSpread({}, this.dto), {}, {
      proposalsCount: count
    }));
  }
}